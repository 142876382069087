import { StoreState } from "../common/redux.store";
import { illegalModeNames } from "../crosswalk/crosswalk-helper";

export function getExistingSources(state: StoreState): string[] | null {
    if (!state.fileUpload.existingCrosswalk) {
        return null;
    }

    return Object.keys(state.fileUpload.existingCrosswalk[0]).filter(source => !illegalModeNames.includes(source));
}

export function getExistingSurveys(state: StoreState) {
    return state.fileUpload.surveys;
}