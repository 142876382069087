import { illegalModeNames, buildEmptyRow, placeholder, isCleanTextMatch } from "./crosswalk-helper";
import { SurveyQuestions, Crosswalk, PersistedCrosswalk, Question, PersistedCrosswalkRow, PersistedCrosswalkCell, MappedQuestion, DataMap } from "./crosswalk.types";
import { Dispatch } from "react";
import { CombinedState } from "redux";
import { StoreState } from "../common/redux.store";
import { stackData, actionTypes } from "./crosswalk-actions";

export function buildCrosswalk() {
    return function (dispatch: Dispatch<any>, getState: () => CombinedState<StoreState>) {

        const state = getState().fileUpload;
        const persistedCrosswalk = state.existingCrosswalk;
        const dataMap = state.dataMap;

        // Combine the provided survey questions with any data maps
        const surveyQuestions =
            Object.entries(state.surveys)
                .map(([mode, survey]) => [mode, addMapToSurvey(mode, survey, dataMap[mode])] as [string, Question[]])
                .reduce((acc, [mode, questions]) => ({ ...acc, [mode]: questions }), {} as Record<string, Question[]>);

        // Build a crosswalk, using an existing if this is an existing project
        const crosswalk = !persistedCrosswalk || persistedCrosswalk.length === 0
            ? buildNewCrosswalk(surveyQuestions)
            : buildFromPersistedCrosswalk(surveyQuestions, persistedCrosswalk);

        dispatch({
            type: actionTypes.SORT_CROSSWALK_QUESTIONS,
            payload: {
                crosswalkRowAssociation: crosswalk
            }
        });

        // save the crosswalk
        dispatch(stackData())
    }
}

function addMapToSurvey(mode: string, survey: Question[], dataMap: DataMap[] | null): Question[] {

    if (dataMap == null) {
        return survey;
    }

    dataMap.forEach(mapping => {
        let matchedQuestion = survey.find(question => question.id.toLowerCase() === mapping.varname.toLowerCase());
        if (matchedQuestion) {
            matchedQuestion.location = mapping.location;
            matchedQuestion.numMult = mapping.nummult;
            matchedQuestion.length = mapping.length;
        } else {
            survey.push({
                id: mapping.varname,
                text: mapping.varname,
                location: mapping.location,
                length: mapping.length,
                numMult: mapping.nummult,
                responses: [],
                mode: mode
            })
        }
    })

    return survey;
}

function buildNewCrosswalk(surveyQuestions: SurveyQuestions): Crosswalk {

    if (!surveyQuestions) {
        throw Error('No survey questions were provided');
    }

    const modes = Object.keys(surveyQuestions);
    if (modes.length === 0) {
        throw Error('No survey questions were provided');
    }

    // We'll always take the first mode as our master. User can change this later
    const masterMode = modes[0];
    const masterSurvey = surveyQuestions[masterMode];

    // Get the surveys that aren't the master survey
    const notMasterSurveys = getRemainingSurveys(surveyQuestions, [masterMode])

    const { crosswalk, remainingQuestions } = matchSurveysToMaster(masterMode, masterSurvey, notMasterSurveys);

    return buildAppCrosswalk(crosswalk, remainingQuestions);
}

function buildFromPersistedCrosswalk(allSurveyQuestions: SurveyQuestions, persistedCrosswalk: PersistedCrosswalk): Crosswalk {

    if (!persistedCrosswalk) {
        throw Error('No persisted crosswalk was provided');
    }

    if (persistedCrosswalk.length === 0) {
        throw Error('The persisted crosswalk provided was empty');
    }

    // The keys will be something like ["Master", "Online", "Phone", "location", "length", "numMult"]
    // We only want ["Online", "Phone"]
    // The master mode will be the first one
    const persistedModes = Object.keys(persistedCrosswalk[0]).filter(key => !illegalModeNames.includes(key));
    const surveyModes = Object.keys(allSurveyQuestions);
    const masterMode = persistedModes[0];

    // Survey modes should have AT LEAST the persisted modes, possibly more. I.e., persistedModes should be a subset of surveyModes
    if (persistedModes.every(pm => !surveyModes.includes(pm))) {
        throw Error('There are modes from the persisted crosswalk that are missing from the survey file choices');
    }

    // Make sure the existing crosswalk has all of the new surveys merged
    const updatedCrosswalk =
        Object.entries(allSurveyQuestions)
              .reduce((prev, [mode, questions]) => mergeSurveysIntoExistingCrosswalk(prev, mode, questions), persistedCrosswalk);

    // If the above subset logic is true, and the lengths are the same, we know they're the same and we can just use the updatedCrosswalk
    if (persistedModes.length === surveyModes.length) {
        return buildAppCrosswalk(updatedCrosswalk, {});
    }

    // If there are extra surveys that don't exist in the persisted crosswalk, we have to match up those to the updatedCrosswalk
    const remainingSurveys = getRemainingSurveys(allSurveyQuestions, persistedModes);
    const masterSurvey = updatedCrosswalk.map(mapping => mapping[masterMode]);
    const { crosswalk: newCrosswalk, remainingQuestions } = matchSurveysToMaster(masterMode, masterSurvey, remainingSurveys);

    const combinedCrosswalk = joinOldAndNewCrosswalks(updatedCrosswalk, newCrosswalk);

    return buildAppCrosswalk(combinedCrosswalk, remainingQuestions);

    function joinOldAndNewCrosswalks(oldCrosswalk: PersistedCrosswalk, newCrosswalk: PersistedCrosswalk): PersistedCrosswalk {
        if (oldCrosswalk.length !== newCrosswalk.length) {
            throw Error('Cannot join two different sized crosswalks')
        }

        return oldCrosswalk.map((oldRow, i) => {
            return {
                ...oldRow,
                ...newCrosswalk[i]
            }
        })
    }
}

function mergeSurveysIntoExistingCrosswalk(existingCrosswalk: PersistedCrosswalk, mode: string, questions: Question[]) {

    // If we're editing an existing crosswalk, we need to make sure the survey/dict combo gets thrown onto that
    return existingCrosswalk.map(row => {
        const item = row[mode];
        if (item) {
            const match = questions.find(q => q.id.toLowerCase() === item.id.toLowerCase())

            return {
                ...row,
                [mode]: {
                    ...item,
                    ...match,
                    responses:item.responses
                }
            }
        }

        return row;
    });
}

function matchSurveysToMaster(masterMode: string, masterSurvey: PersistedCrosswalkCell[], surveys: SurveyQuestions): { crosswalk: PersistedCrosswalk, remainingQuestions: SurveyQuestions } {

    const surveyQuestions = Object.entries(surveys)

    const crosswalk = masterSurvey.map(masterQuestion => {

        const newMapping: Record<string, Question | null> = {
            [masterMode]: masterQuestion || null
        };

        for (const [mode, availableQuestions] of surveyQuestions) {

            if (!masterQuestion) {
                // The master question may not exist if a non-master question isn't mapped to anything
                newMapping[mode] = null;
            } else {
                const [match, index] = findClosestMatch(masterQuestion, availableQuestions);

                // Remove the question we found from available questions to avoid duplicate matches
                if (match !== null && index !== null) {
                    availableQuestions.splice(index, 1);
                }

                newMapping[mode] = match;
            }
        }

        return newMapping;
    });

    const remainingQuestions = surveyQuestions.reduce((acc, [mode, questions]) => ({ ...acc, [mode]: questions }), {} as SurveyQuestions);

    return { crosswalk, remainingQuestions };
}

function findClosestMatch(baseQuestion: Question, availableQuestions: Question[]): [Question | null, number | null] {

    let id = baseQuestion.id;

    let questionByTextIndex = availableQuestions.findIndex(question => isCleanTextMatch(question, baseQuestion));
    if (questionByTextIndex !== -1) {
        return [availableQuestions[questionByTextIndex], questionByTextIndex];
    }

    let questionByIdIndex = availableQuestions.findIndex(question => id.toLowerCase() === question.id.toLowerCase());
    if (questionByIdIndex !== -1) {
        return [availableQuestions[questionByIdIndex], questionByIdIndex];
    }

    return [null, null];
}

function buildAppCrosswalk(crosswalk: PersistedCrosswalk, remainingQuestions: SurveyQuestions): Crosswalk {

    const allModes = Object.keys(crosswalk[0]).filter(key => !illegalModeNames.includes(key));

    const appCrosswalk = crosswalk.map(row => {
        return Object.entries(row).filter(([mode]) => allModes.includes(mode)).map(([mode, question]) => asMappedQuestion(question, mode))
    })

    const remainingQuestionsCrosswalk = Object.entries(remainingQuestions).flatMap(([mode, questions]) => {
        return questions.map(q => buildEmptyRow(allModes, asMappedQuestion(q, mode), allModes.findIndex(m => m === mode)));
    });

    return appCrosswalk.concat(remainingQuestionsCrosswalk);

    function asMappedQuestion(question: PersistedCrosswalkRow[string], mode: string): MappedQuestion {

        if (!question) {
            return placeholder(mode);
        }

        return {
            mode: mode,
            id: question.id,
            text: question.text,
            responses: question.responses,
            location: question.location,
            numMult: question.numMult,
            length: question.length,
            isOpen: false
        }
    }
}

function getRemainingSurveys(surveyQuestions: SurveyQuestions, existingModes: string[]): SurveyQuestions {
    const surveyCopy = { ...surveyQuestions };
    for (const mode of existingModes) {
        delete surveyCopy[mode];
    }

    return surveyCopy;
}
