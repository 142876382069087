import { Card, CardContent, Collapse, InputAdornment, List, ListItem, ListItemIcon, ListItemText, TextField, Typography, makeStyles } from "@material-ui/core";
import { ArrowForward, ExpandLess, ExpandMore, Person, Restore, ViewList, Search } from "@material-ui/icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../common/redux.store";
import { getExistingProjects } from "../app/app.actions";
import { ExistingProject } from "../crosswalk/crosswalk.types";
import { downloadCrosswalk } from "../survey-upload/file-upload.actions";

function xDaysAgo(x: number) {
    return new Date(new Date().setDate(new Date().getDate() - x));
}

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: "10px 20px",
        width: "calc(100% - 40px)"
    },
}));

function SelectExistingProject() {

    const [recentOpen, setRecentOpen] = useState(true);
    const [allOpen, setAllOpen] = useState(false);
    const [myOpen, setMyOpen] = useState(false);

    const dispatch = useDispatch();

    const classes = useStyles();

    const existingProjects = useSelector((state: StoreState) => state.app.existingProjects);

    return (
        <Card>
            <CardContent>
                <Typography variant="h6">Use Existing</Typography>
                <TextField
                    className={classes.margin}
                    label="Search"
                    onChange={e => dispatch(getExistingProjects(e.target.value))}
                    InputProps={{

                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }} />
                <List style={{ height: "300px", overflowY: "auto" }}>
                    {renderProjectList()}
                </List>
            </CardContent>
        </Card>
    );

    function renderProjectList() {
        return (
            <>
                {/* Recents */}
                <ListItem button onClick={() => setRecentOpen(!recentOpen)}>
                    <ListItemIcon>
                        <Restore />
                    </ListItemIcon>
                    <ListItemText primary="Recents" />
                    {recentOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={recentOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {renderRecent()}
                    </List>
                </Collapse>

                {/* My Projects */}
                <ListItem button onClick={() => setMyOpen(!myOpen)}>
                    <ListItemIcon>
                        <Person />
                    </ListItemIcon>
                    <ListItemText primary="Mine" />
                    {myOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={myOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {renderMine()}
                    </List>
                </Collapse>

                {/* All Projects */}
                <ListItem button onClick={() => setAllOpen(!allOpen)}>
                    <ListItemIcon>
                        <ViewList />
                    </ListItemIcon>
                    <ListItemText primary="All" />
                    {allOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={allOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {renderAll()}
                    </List>
                </Collapse>
            </>
        )

        function renderRecent() {
            return existingProjects.filter(p => p.lastUpdate >= xDaysAgo(7))
                .sort((a, b) => b.lastUpdate.getTime() - a.lastUpdate.getTime())
                .map(p => renderItem(p, 'recent'));
        }

        function renderMine() {
            return existingProjects.filter(p => p.isMine)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(p => renderItem(p, 'mine'));
        }

        function renderAll() {
            return existingProjects.sort((a, b) => a.name.localeCompare(b.name))
                .map(p => renderItem(p, 'all'));
        }

        function renderItem(project: ExistingProject, type: string) {
            return (
                <ListItem key={project.id} button style={{ paddingLeft: "32px" }} onClick={() => projectSelected(project)}>
                    <ListItemIcon>
                        <ArrowForward style={{ fontSize: "1rem" }} />
                    </ListItemIcon>
                    <ListItemText id={type + '-' + project.name} primary={project.name} />
                </ListItem>
            )
        }

        function projectSelected(project: ExistingProject) {
            dispatch(downloadCrosswalk(project));
        }
    }
}

export default SelectExistingProject