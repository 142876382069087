import { StoreState } from "../redux.store";

export function getCrosswalkModes(state: StoreState) {
    return (state.crosswalk.crosswalkRowAssociation[0] || []).map(r => r.mode);
}

export function isCrosswalkSorted(state: StoreState) {
    return state.crosswalk.sorted;
}

export function getPanels(state: StoreState) {
    return state.panels;
}

export function isCrosswalkSaving(state: StoreState) {
    return state.crosswalk.isSaving;
}

export const getMasterSurvey = (s: StoreState) => s.crosswalk.masterSurvey;