import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';

export function CrosswalkHeader({stackSources}: {stackSources: (string | undefined)[]}) {
  
  return (
    <Grid container spacing={2}>
      {stackSources.map((s, i) => (
        <Grid item key={i}>
          <Typography variant="h6" color="primary">{s} {i === 0 ? '(Master)' : ''}</Typography>
        </Grid>
      ))}
    </Grid>
  );
}