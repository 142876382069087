import { Action } from "redux";

type ValueOf<T> = T[keyof T];

type ReturnFunction = (...args: any) => any;
export type ActionReturnType<T extends ReturnFunction> = ReturnType<T> & Action;

export function createReducer<TState, TActions extends Record<PropertyKey, Action<keyof TActions>>>(
    initialState: TState,
    handlers: Partial<{ [K in keyof TActions]: (s: TState, a: TActions[K]) => TState }>
    ) : (state: TState, action: ValueOf<TActions>) => TState {
    return (state, action) => {
        if (typeof state === 'undefined') {
            return initialState;
        }

        const handler = handlers[action.type];
        if (handler == null)
            return state;

        const result = handler(state, action);
        if (typeof result === 'object' && result === null) {
            return state;
        }

        return {
            ...state,
            ...result
        }
    };
}
