import { createReducer } from 'async-redux-helpers';
import { actions } from './expansion-panel.actions';
import { panelNames } from './expansion-panel.types';

const initialState = {
    [panelNames.SELECT_PROJECT]: true,
    [panelNames.UPLOAD_SURVEY]: false,
    [panelNames.BUILD]: false,
    [panelNames.DOWNLOAD]: false
};

const panelReducer = createReducer(initialState, {
    [actions.OPEN_PANEL]: (state = {}, {payload}) => ({
        [panelNames.SELECT_PROJECT]: false,
        [panelNames.UPLOAD_SURVEY]: false,
        [panelNames.BUILD]: false,
        [panelNames.DOWNLOAD]: false,
        ...{
            [payload.panel]: true
        }
    }),
    [actions.SET_PANEL]: (state = {}, {payload}) => ({
        ...state,
        ...{
            [payload.panel]: payload.isExpanded
        }
    }),
})

export default panelReducer