const styles = {
  buttonContainer: {
    justifyContent: 'center',
    display: 'flex',
    width: '100%'
  },
  button: {
    margin: '10px'
  }
}

export default styles;