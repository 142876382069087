
import { ActionReturnType } from './../common/redux.helpers';
import { Dispatch, CombinedState } from 'redux';
import { StoreState } from '../common/redux.store';
import { getProjects, addProject } from '../crosswalk-persistence/api.client';
import { ExistingProject } from '../crosswalk/crosswalk.types';
import { openFileUpload } from '../common/expansion-panel/expansion-panel.actions';

export type AppActions = {
    'SET_AVAILABLE_PROJECTS': ActionReturnType<typeof setProjects>,
    'SET_CURRENT_PROJECT': ActionReturnType<typeof storeCurrentProject>,
    'CREATE_PROJECT_ERROR': ActionReturnType<typeof createProjectError>,
}

export const actionTypes = {
    SET_AVAILABLE_PROJECTS: 'SET_AVAILABLE_PROJECTS',
    SET_CURRENT_PROJECT: 'SET_CURRENT_PROJECT',
    CREATE_PROJECT_ERROR: 'CREATE_PROJECT_ERROR',
}

export function getExistingProjects(searchName: string) {
    return async function (dispatch: Dispatch<any>, getState: () => CombinedState<StoreState>) {
        const { accessToken, isAuthenticated } = getState().authState;

        if (!isAuthenticated) {
            return dispatch(setProjects([]));
        }

        const projects = await getProjects(searchName || '*', accessToken);

        dispatch(setProjects(projects));
    }
};

export function createNewProject(projectName: string) {
    
    return async function (dispatch: Dispatch<any>, getState: () => CombinedState<StoreState>) {
        const { accessToken, isAuthenticated } = getState().authState;

        if (!isAuthenticated) {
            throw Error('Cannot create new project for unathenticated user')
        }

        dispatch(createProjectError(''))

        await addProject(projectName, accessToken).then(projectId => {
            dispatch(storeCurrentProject({
                id: projectId,
                name: projectName,
                isMine: true,
                lastUpdate: new Date()
            }))
            
            dispatch(openFileUpload());
        })
        .catch((e: Error) => {
            dispatch(createProjectError(e.message))
        });        
    }
};

export function createProjectError(errorMessage: string) {
    return {
        type: actionTypes.CREATE_PROJECT_ERROR,
        payload: {
            message: errorMessage
        }
    }
}

export function storeCurrentProject(project: ExistingProject) {
    
    return {
        type: actionTypes.SET_CURRENT_PROJECT,
        payload: {
            project
        }
    }
};

function setProjects(projects: ExistingProject[]) {
    return {
        type: actionTypes.SET_AVAILABLE_PROJECTS,
        payload: {
            projects
        }
    }
};
