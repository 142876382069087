import { PanelId, panelNames } from "./expansion-panel.types"

export const actions = {
    OPEN_PANEL: 'OPEN_PANEL',
    SET_PANEL: 'SET_PANEL'
}

export function setPanel(panel: PanelId, isExpanded: boolean) {
    return {
        type: 'SET_PANEL',
        payload: {
            panel: panelNames[panel],
            isExpanded: isExpanded
        }
    }
}

export function setOnlyOpenPanel(panel: PanelId) {
    return {
        type: 'OPEN_PANEL',
        payload: {
            panel: panelNames[panel]
        }
    }
}

export function openDataDownload() {
    return setOnlyOpenPanel('DOWNLOAD')
}

export function openFileUpload() {
    return setOnlyOpenPanel('UPLOAD_SURVEY')
}

export function openBuildCrosswalk() {
    return setOnlyOpenPanel('BUILD')
}