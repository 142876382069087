import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import loggerMiddleware from 'redux-logger';
import thunkMiddlware from 'redux-thunk';
import authenticationReducer from '../authentication/authentication.reducer';
import { DeletedCrosswalk, MappedQuestion } from '../crosswalk/crosswalk.types';
import crosswalkReducer from '../crosswalk/crosswalk-reducer';
import fileUploadReducer, { FileUploadState } from '../survey-upload/file-upload.reducers';
import { loadState } from './redux/localStorage.middleware';
import appReducer, { AppState } from '../app/app.reducers';
import panelReducer from './expansion-panel/expansion-panel-reducers';
import { PanelsState } from './expansion-panel/expansion-panel.types';

const reducers = {
    fileUpload: fileUploadReducer,
    crosswalk: crosswalkReducer,
    panels: panelReducer,
    authState: authenticationReducer,
    app: appReducer
};
 
export type StoreState = {
    app: AppState;
    fileUpload: FileUploadState;
    authState: AuthState;
    crosswalk: CrosswalkState;
    panels: PanelsState;
};

export type AuthState = {
    isAuthenticated: boolean;
    accessToken: string;
}

export type CrosswalkState = {
    crosswalkRowAssociation: MappedQuestion[][];
    deletedCrosswalkQuestions: DeletedCrosswalk;
    masterSurvey: string;
    sorted: boolean;
    isSaving: boolean;
}

const rootReducer = combineReducers(reducers);

const persistedState = loadState();

const store = createStore(
    rootReducer,
    persistedState,
    compose(
        applyMiddleware(
            thunkMiddlware,
            loggerMiddleware,
        ),
    )
);

export default store;
