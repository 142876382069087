import React, { useState } from 'react';
import { AppBar, Typography, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { isCrosswalkSorted } from '../../common/redux/redux.selectors';

const styles = {
    title: {
        flexGrow: 1,
        color: '#009ADA',
        padding: '20px',
        paddingLeft: '24px'
    }
};

const DataStackerNavigation = () => {

    const isLocked = useSelector(isCrosswalkSorted);
    const [isOpen, setOpen] = useState(false);

    const resetButton = isLocked
        ? <Button variant="contained" color="primary" className="reset-button" onClick={() => setOpen(true)}>Reset</Button>
        : null;

    return (
        <>
            <AppBar position='static' color='default'>
                <Typography variant='h4' style={styles.title}>
                    Data Stacker
                </Typography>
                {resetButton}
            </AppBar>
            <Dialog
                open={isOpen}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Reset Page?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        If any changes have not been saved or downloaded, you may lose your work. Are you sure you wish to continue?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)} color="secondary">
                        No, go back
                    </Button>
                    <Button onClick={resetPage} color="primary" variant="contained" autoFocus>
                        Yes, reset form
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );

    function resetPage() {
        window.location.reload();
    }
}

export default DataStackerNavigation;