import { Card, CardContent, Collapse, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { green, red, yellow } from '@material-ui/core/colors';
import { Clear, Delete, DoneAll, ExpandLess, ExpandMore, WarningOutlined, CallSplit } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Placeholder } from './crosswalk-placeholder';
import { CrosswalkQuestionResponses } from './crosswalk-response';
import { ItemTypes } from './item-types';
import { useDispatch } from 'react-redux';
import { toggleResponseIsOpen, swapQuestions, splitRow, deleteQuestion } from './crosswalk-actions';
import { DraggedQuestion, MappedQuestionRow, MappedQuestion } from './crosswalk.types';
import { getCleanText, isCleanTextMatch, isPlaceholder } from './crosswalk-helper';

export function CrosswalkQuestion({
    question,
    rowIndex,
    reMeasure
}: {
    question?: MappedQuestion,
    rowIndex: number,
    reMeasure: () => void
}) {

    const [, ref] = useDrag({
        item: { type: ItemTypes.QUESTION, question: question, indexFrom: rowIndex } as DraggedQuestion,
        collect: monitor => ({
            isDragging: !!monitor.isDragging()
        })
    })

    const dispatch = useDispatch();

    useEffect(reMeasure, [question?.isOpen])

    if (question === undefined)
        return null;
 
    const text = getCleanText(question);

    const toggleExpansion = () => {
        dispatch(toggleResponseIsOpen(rowIndex, question.mode, !question.isOpen))
    };

    return (
        <Card ref={ref}>
            <CardContent>
                <Tooltip title="Delete question" className="question-delete-tooltip">
                    <IconButton onClick={() => dispatch(deleteQuestion(rowIndex, question.mode))}>
                        <Delete />
                    </IconButton>
                </Tooltip>
                <Typography variant="subtitle2" color="primary">{question?.id}</Typography>
                <Typography variant="caption" color="textSecondary">{text}</Typography>

                <IconButton
                    className="expand-button"
                    onClick={toggleExpansion}
                    aria-expanded={question.isOpen}
                    aria-label="show more">
                    {!question.isOpen ? <ExpandMore /> : <ExpandLess />}
                </IconButton>
            </CardContent>
            <Collapse in={question.isOpen} timeout={0} unmountOnExit>
                <CardContent className="responses-container">
                    <CrosswalkQuestionResponses
                        question={question}
                        rowIndex={rowIndex}
                        mode={question.mode}
                        reMeasure={reMeasure}
                    />
                </CardContent>
            </Collapse>
        </Card>
    )
}

export function QuestionRow({
    row,
    rowIndex,
    reMeasure
}: {
    row: MappedQuestionRow,
    rowIndex: number,
    reMeasure: () => void
}) {
    const dispatch = useDispatch();

    const [{ item }, drop]: [{ canDrop: boolean, item: DraggedQuestion }, any] = useDrop({
        accept: ItemTypes.QUESTION,
        drop: () => moveQuestion(item),
        collect: monitor => ({
            canDrop: !!monitor.canDrop(),
            item: monitor.getItem() as DraggedQuestion
        })
    });

    const isDirectMatch = () => {
        let masterCard = row[0];

        return row.every(question => isCleanTextMatch(question, masterCard) && (question.id === masterCard.id))
    }

    const isCloseMatch = () => {
        let masterCard = row[0];

        if (row.every(question => question.id === masterCard.id)) {
            return true;
        }
        else if (row.every(question => isCleanTextMatch(question, masterCard))) {
            return true;
        }
        else {
            return false;
        }
    }

    function renderItem(q: MappedQuestion | undefined) {
        if (q?.id === 'PLACEHOLDER') {
            return <Placeholder
                rowIndex={rowIndex}
                mode={q?.mode}
            />
        }
        else {
            return (
                <CrosswalkQuestion
                    question={q}
                    rowIndex={rowIndex}
                    reMeasure={reMeasure}
                />
            )
        }
    }

    return (
        <Grid container className="crosswalk-row" innerRef={drop} spacing={2}>
            <Grid className="crosswalk-row-icon" item>{getMatchIcon()}</Grid>
            {renderItems()}
        </Grid>
    )

    function renderItems() {
        let pairs = row.map(question => (
            <Grid className="crosswalk-row-item" key={`${question.mode}_${question.id}`} item>
                <div className="crosswalk-row-contents">
                    {renderItem(question)}
                </div>
            </Grid>
        ));

        return (
            <>
                <Grid className="crosswalk-split-icon" item>{getSplitIcon()}</Grid>
                {pairs}
            </>
        )
    }

    function getSplitIcon() {
        if (row.filter(r => !isPlaceholder(r)).length === 1) {
            return null;
        }

        return (
            <IconButton onClick={() => dispatch(splitRow(rowIndex))}>
                <CallSplit />
            </IconButton>
        )
    }

    function getMatchIcon() {
        if (isDirectMatch()) {
            return (<DoneAll style={{ color: green[500] }} />)
        }
        else if (isCloseMatch()) {
            return (<WarningOutlined style={{ color: yellow[500] }} />)
        }
        else {
            return (<Clear style={{ color: red[500] }} />)
        }
    }

    function moveQuestion(draggedQuestion: DraggedQuestion) {
        dispatch(swapQuestions(draggedQuestion.question.mode, rowIndex, draggedQuestion.indexFrom));
    };

}
