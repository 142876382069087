import { Grid } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CreateProject from './create-project';
import SelectExistingProject from './select-existing-project';
import { StoreState } from '../common/redux.store';
import { getExistingProjects } from '../app/app.actions';

const CrosswalkProjectDownload = () => {
    
    const authState = useSelector((s: StoreState) => s.authState);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getExistingProjects('*'))
    }, [authState, dispatch]);

    return (
        <Grid container direction="row" justify="center" alignItems="center">
            <Grid container item xs={12} spacing={3} >
                <Grid item xs={6} >
                    <CreateProject />
                </Grid>
                <Grid item xs={6}>
                    <SelectExistingProject />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default CrosswalkProjectDownload;