import { createReducer } from 'async-redux-helpers';
import { actionTypes as actions } from './authentication.actions';

const initialState =   {
  authState: {}
};

const authenticationReducer = createReducer(
  initialState,
  {
    [actions.SET_AUTH]: (state, action) => ({
      ...state,
      ...action.payload.authState,
    })
  }
);

export default authenticationReducer;