import { FileUploadActions } from './file-upload.actions';
import { createReducer } from '../common/redux.helpers';
import { PersistedCrosswalk, Question } from '../crosswalk/crosswalk.types';
import { SavedFiles } from './survey-upload-box.component';

export type FileUploadState = {
    existingCrosswalk: PersistedCrosswalk | null;
    surveys: Record<string, Question[]>;
    dataMap: Record<string, any>;
    data: Record<string, any>;
}

const initialState: FileUploadState = {
    existingCrosswalk: null,
    surveys: {},
    dataMap: {},
    data: {}
};

const fileUploadReducer = createReducer<FileUploadState, FileUploadActions>(initialState,
    {
        'SET_EXISTING_CROSSWALK': (state, { payload }) => ({
            ...state,
            existingCrosswalk: payload.persistedCrosswalk
        }),
        'SET_EXISTING_SURVEY': (state, { payload }) => setExistingSurvey(state, payload.mode, payload.surveyQuestions),
        'SET_UPLOADED_DATA': (state, {payload}) => setAllUploadedData(state, payload.uploadedData)
    }
);

function setAllUploadedData(state: FileUploadState, uploadedData: SavedFiles): FileUploadState {
    return {
        ...state,
        surveys: {
            ...state.surveys,
            [uploadedData.mode]: uploadedData.survey
        },
        dataMap: {
            ...state.dataMap,
            [uploadedData.mode]: uploadedData.dataMap
        },
        data: {
            ...state.data,
            [uploadedData.mode]: uploadedData.results
        }
    }
}

function setExistingSurvey(state: FileUploadState, mode: string, questions: Question[]) {
    return {
        ...state,
        surveys: {
            ...state.surveys,
            [mode]: questions
        }
    };
}

export default fileUploadReducer;