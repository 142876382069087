import { Card, CardContent, Collapse, IconButton, Tooltip, Typography } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import React, { useState } from 'react';
import { CrosswalkQuestionResponses } from './crosswalk-response';
import { useDispatch, useSelector } from 'react-redux';
import { undeleteQuestion } from './crosswalk-actions';
import {  MappedQuestion } from './crosswalk.types';
import { StoreState } from '../common/redux.store';

export function CrosswalkDeletedContainer() {
    const deletedQuestions = useSelector((s: StoreState) => s.crosswalk.deletedCrosswalkQuestions)
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();

    const renderDeletedCardList = (mode: string, deletedCards: MappedQuestion[], i: number) => {
        return (
            <div key={i}>
                <Typography variant="h6">{mode}</Typography>
                {deletedCards.map(renderDeletedCard)}
            </div>
        )
    }

    const renderDeletedCard = (question: MappedQuestion, index: number) => {
        const text = question?.text?.replace(RegExp(`^${question?.id}. `), '');

        return (
            <Card className="crosswalk-deleted-card" key={index}>
                <CardContent>
                    <Tooltip title="Add question" className="question-delete-tooltip">
                        <IconButton onClick={() => dispatch(undeleteQuestion(question.mode, index))}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>

                    <Typography variant="subtitle2" color="primary">{question?.id}</Typography>
                    <Typography variant="caption" color="textSecondary">{text}</Typography>

                    <IconButton
                        className="expand-button"
                        onClick={() => setIsOpen(!isOpen)}
                        aria-expanded={isOpen}
                        aria-label="show more">
                        {!isOpen ? <ExpandMore /> : <ExpandLess />}
                    </IconButton>
                </CardContent>
                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <CardContent className="responses-container">
                        <CrosswalkQuestionResponses question={question} reMeasure={() => {}} />
                    </CardContent>
                </Collapse>
            </Card>
        )
    }

    return (
        <div className="crosswalk-deleted-container">
            <Typography variant="h5">Deleted Questions</Typography>
            {Object.entries(deletedQuestions).map(([mode, deletedCards], i) => renderDeletedCardList(mode, deletedCards, i))}
        </div>
    )
}