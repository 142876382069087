import { uploadCrosswalk } from '../crosswalk-persistence/api.client';
import { Dispatch, CombinedState } from 'redux';
import { StoreState } from '../common/redux.store';
import { Question, PersistedCrosswalk, StackData, DeletedCrosswalk } from './crosswalk.types';
import { delay } from '../common/react.helpers';
import { settings } from "../../appSettings"

export const actionTypes = {
    SORT_CROSSWALK_QUESTIONS: 'SORT_CROSSWALK_QUESTIONS',
    BUILD_CROSSWALK: 'BUILD_CROSSWALK',
    TOGGLE_REPONSE_IS_OPEN_ALL: 'TOGGLE_REPONSE_IS_OPEN_ALL',
    TOGGLE_REPONSE_IS_OPEN: 'TOGGLE_REPONSE_IS_OPEN',
    DELETE_QUESTION: 'DELETE_QUESTION',
    UNDELETE_QUESTION: 'UNDELETE_QUESTION',
    SWAP_QUESTIONS: 'SWAP_QUESTIONS',
    SPLIT_ROW: 'SPLIT_ROW',
    UPDATE_RESPONSE_MAPPING: 'UPDATE_RESPONSE_MAPPING',
    ADD_PUNCH: 'ADD_PUNCH',
    SET_MASTER_SURVEY: 'SET_MASTER_SURVEY',
    IS_CROSSWALK_SAVING: 'IS_CROSSWALK_SAVING',
    SET_DELETED_CROSSWALK: 'SET_DELETED_CROSSWALK',
};


export function setMasterSurvey(surveyName) {
    return {
        type: actionTypes.SET_MASTER_SURVEY,
        payload: {
            surveyName 
        }
    }
}

export function addPunch(rowIndex, mode, newPunchId, newPunchText) {
    return {
        type: actionTypes.ADD_PUNCH,
        payload: {
            rowIndex, mode, newPunchId, newPunchText
        }
    }
}

export function updateResponseMapping(rowIndex, mode, nonMasterResponseId, masterResponseId) {
    return {
        type: actionTypes.UPDATE_RESPONSE_MAPPING,
        payload: {
            rowIndex, mode, nonMasterResponseId, masterResponseId
        }
    }
}

export function deleteQuestion(rowIndex, mode) {
    return {
        type: actionTypes.DELETE_QUESTION,
        payload: {
            rowIndex, mode
        }
    }
}

export function splitRow(rowIndex) {
    return {
        type: actionTypes.SPLIT_ROW,
        payload: {
            rowIndex
        }
    }
}

export function undeleteQuestion(mode, deletedIndex, intoIndex?) {
    return {
        type: actionTypes.UNDELETE_QUESTION,
        payload: {
            mode, deletedIndex, intoIndex
        }
    }
}

export function swapQuestions(mode, index1, index2) {
    return {
        type: actionTypes.SWAP_QUESTIONS,
        payload: {
            mode, index1, index2
        }
    }
}

export const toggleAllResponseIsOpen = (isOpen) => {
    return {
        type: actionTypes.TOGGLE_REPONSE_IS_OPEN_ALL,
        payload: {
            isOpen
        }
    }
}

export const toggleResponseIsOpen = (rowIndex, mode, isOpen) => {
    return {
        type: actionTypes.TOGGLE_REPONSE_IS_OPEN,
        payload: {
            rowIndex,
            mode,
            isOpen
        }
    }
}

export function storeIsCrosswalkSaving(isSaving: boolean) {
    return {
        type: actionTypes.IS_CROSSWALK_SAVING,
        payload: {
            isSaving
        }
    }
}

export function storeDeleted(deletedCrosswalk: DeletedCrosswalk) {
    return {
        type: actionTypes.SET_DELETED_CROSSWALK,
        payload: {
            deletedCrosswalk
        }
    }
}

export const stackData = () => async (dispatch: Dispatch<any>, getState: () => CombinedState<StoreState>) => {
    const state = getState();
    const surveyQuestions = state.crosswalk.crosswalkRowAssociation;
    const dataDict = state.fileUpload.data;

    dispatch(storeIsCrosswalkSaving(true));
    await delay(100);

    let masterSurvey = ""; 
    let modeArray = surveyQuestions[0].map(modeEntry => modeEntry.mode);
    
    let allSurveyMappings = [] as PersistedCrosswalk;
    surveyQuestions.forEach(row => {
      let surveyQuestion = buildBase(modeArray);
      row.forEach(question => {
        let surveyName = question.mode;
        if (surveyName === masterSurvey) {
          if (question.id === "PLACEHOLDER" ) {
            surveyQuestion["Master"] = null;
          }
          else {
            surveyQuestion["Master"] = Object.assign({}, question)
            if (allSurveyMappings.map(survey => survey['Master']?.id).includes(question.id)) {
                surveyQuestion["Master"].id = question.id.concat("_", question.mode.toUpperCase());
            }
          }
        }
        if (surveyQuestion["Master"] === null && (question.id !=="PLACEHOLDER")) {
            surveyQuestion["Master"] = Object.assign({}, question)
            if (allSurveyMappings.map(survey => survey['Master']?.id).includes(question.id)) {
                surveyQuestion["Master"].id = question.id.concat("_", question.mode.toUpperCase());
              }
        }
        if (question.id === "PLACEHOLDER") {
          surveyQuestion[surveyName] = null;
        }
        else {
          surveyQuestion[surveyName] = question;
        } 
      })

      allSurveyMappings.push(surveyQuestion);
    })

    if (settings.app.includeDeletedQuestionsInMap) {
        const deletedQuestions = state.crosswalk.deletedCrosswalkQuestions;
        Object.entries(deletedQuestions).forEach(([deletedMode, questions]) => {
            questions.forEach(question => {
                let surveyQuestion = buildBase(modeArray)
                question['deleted'] = true;
                surveyQuestion["Master"] = question;
                modeArray.forEach(mode => {
                    if (mode === deletedMode) {
                        surveyQuestion[mode] = question;
                    }
                    else {
                        surveyQuestion[mode] = null;
                    }
                })
                allSurveyMappings.push(surveyQuestion);
            }) 
        })
    }

    let maptext = runMap(allSurveyMappings, modeArray);
    let datatext = runData(allSurveyMappings, dataDict);

    dispatch(sendDataFiles(maptext, datatext, allSurveyMappings));
  
    function buildBase(modes: string[]): Record<string, Question | null> {
        let base = {
            "Master": null
        };
        modes.forEach(mode => {
            base[mode] = null;
        })
        return base;
    };
}

const sendDataFiles = (maptext, datatext, mapping: PersistedCrosswalk) => {

    return async function (dispatch: Dispatch<any>, getState: () => CombinedState<StoreState>) {
        dispatch({
            type: actionTypes.BUILD_CROSSWALK,
            payload: {
                maptext, datatext
            }
        });

        const state = getState();
        const { accessToken, isAuthenticated } = state.authState;

        if (!isAuthenticated) {
            throw Error('Cannot upload surveys for unathenticated user');
        }

        if (!state.app.currentProject) {
            throw Error('Cannot upload surveys without a current project');
        }

        await uploadCrosswalk(state.app.currentProject.id, mapping, state.crosswalk.deletedCrosswalkQuestions, accessToken);
        
        dispatch(storeIsCrosswalkSaving(false));
    }
};

type StackingCrosswalk = PersistedCrosswalk & StackData[]

const runMap = (masterMap: StackingCrosswalk, surveyNames: string[]) => {
    let currentSurvey, currentQuestion
    let cur_loc = 1;

    // Iterate through each master question
    // For each survey, get length, location, nummult of the corresponding question
    // Calculate length, location, nummult for master survey
    for (let i = 0; i < masterMap.length; i++) {
        let questionMap = masterMap[i]
        masterMap[i]['location'] = cur_loc.toString();

        let max_single_len = 0;
        let max_nmult = 0;

        for (let j = 0; j < surveyNames.length; j++) {
            currentSurvey = surveyNames[j];
            currentQuestion = questionMap[currentSurvey];
            if (currentQuestion !== null && Object.entries(currentQuestion).length !== 0) {
                let cur_len = parseInt(currentQuestion['length']);
                let cur_nmult = parseInt(currentQuestion['numMult']);
                let cur_single_len = cur_len / cur_nmult;

                if (cur_nmult > max_nmult) {
                    max_nmult = cur_nmult;
                }

                if (cur_single_len > max_single_len) {
                    max_single_len = cur_single_len;
                }
            }
        }

        let len = max_single_len * max_nmult;
        masterMap[i]['length'] = len.toString();
        masterMap[i]['numMult'] = max_nmult.toString();
        cur_loc = cur_loc + len;
    }

    // add survey source variable
    let ssrc = {} as any;

    let max_sname_len = 0;
    for (let i = 0; i < surveyNames.length; i++) {
        if (surveyNames[i].length > max_sname_len) {
            max_sname_len = surveyNames[i].length;
        }
    }

    ssrc['Master'] = {
        id: 'STKSRC',
    }

    ssrc.location = cur_loc;
    ssrc.length = max_sname_len;
    ssrc.numMult = 1;

    masterMap.push(ssrc);


    // create map .txt text
    let text = 'varname\tlocation\tlength\tnummult\n';
    for (let i = 0; i < masterMap.length; i++) {
        text += masterMap[i]['Master']?.id + "\t";
        text += masterMap[i].location + "\t";
        text += masterMap[i].length + "\t";
        text += masterMap[i].numMult + "\n";
    }
    return text;
}

const runData = (masterMap: StackingCrosswalk, dataDict: Record<string, string>) => {
    let datatext = "";
    for (const [mode, content] of Object.entries(dataDict)) { // survey data iteration
        for (const surveyRow of content.split("\n").filter(r => r.trim() !== '')) { // data row iteration
            let currentLine = '';
            for (const masterInfo of masterMap) {
                
                let master_len = parseInt(masterInfo['length'] || '0', 10);
                let master_nmult = parseInt(masterInfo['numMult'] || '0', 10);

                let question = masterInfo[mode];

                if (masterInfo['Master']?.id === 'STKSRC') {
                    currentLine += mode;
                    continue;
                } 

                if (question === null || question.location === undefined || question.length === undefined || question.numMult === undefined) {
                    //append n empty spaces based on master length
                    currentLine += " ".repeat(master_len);
                    continue;
                }

                let orig_loc = parseInt(question['location'], 10);
                let orig_len = parseInt(question['length'], 10);
                let orig_nmult = parseInt(question['numMult'], 10);

                let data = surveyRow.substring(orig_loc - 1, orig_loc + orig_len - 1).replace(/[\r\n]+$/gi, '');
                if (master_nmult > 1) {
                    let multdata = splitString(data, orig_len / orig_nmult);
                    for (let k = 0; k < master_nmult; k++) {
                        if (k < multdata.length) {
                            let mappedMultdata = mapResponseValue(multdata[k], question.responses).toString();
                            currentLine += mappedMultdata.padStart(master_len / master_nmult);
                        } else {
                            currentLine += " ".repeat(master_len / master_nmult);
                        }
                    }
                } else {
                    data = mapResponseValue(data, question.responses).toString();
                    currentLine += data.padStart(master_len);
                }
            }
            datatext += currentLine;
            datatext += "\n";
        }
    }

    return datatext;
}

const mapResponseValue = (data: string, surveyQuestionResponses) => {
    let response = surveyQuestionResponses.find(response => response.id === parseInt(data.toString(), 10));
    if (response) {
        let responseMap = response.mapping;
        return (Object.keys(responseMap).length === 0) ? data : responseMap.mappedResponseId;
    }
    else {
        return data;
    }
}

function splitString(val: string, size: number): string[] {
    if (size === 0) {
        return [];
    }
    
    let re = new RegExp('.{1,' + size + '}', 'g');
    return val.match(re) || [];
}
