
import { ActionReturnType } from '../common/redux.helpers';
import { ExistingProject, PersistedCrosswalk, Question } from '../crosswalk/crosswalk.types';
import { Dispatch } from 'react';
import { CombinedState } from 'redux';
import { StoreState } from '../common/redux.store';
import { getCrosswalk, getSurveyFile, saveSurveyFile } from '../crosswalk-persistence/api.client';
import { parseSurveyFile } from '../crosswalk/crosswalk.file-parsing';
import { readBlobAsText } from '../common/react.helpers';
import { storeCurrentProject } from '../app/app.actions';
import { illegalModeNames } from '../crosswalk/crosswalk-helper';
import { openFileUpload } from '../common/expansion-panel/expansion-panel.actions';
import { SavedFiles } from './survey-upload-box.component';
import { storeDeleted } from '../crosswalk/crosswalk-actions';

export type FileUploadActions = {
    'SET_EXISTING_CROSSWALK': ActionReturnType<typeof storeCrosswalk>,
    'SET_EXISTING_SURVEY': ActionReturnType<typeof storeExistingSurvey>,
    'SET_UPLOADED_DATA': ActionReturnType<typeof storeUploadData>
}

export const actionTypes = {
    SET_EXISTING_CROSSWALK: 'SET_EXISTING_CROSSWALK',
    SET_EXISTING_SURVEY: 'SET_EXISTING_SURVEY',
    SET_UPLOADED_DATA: 'SET_UPLOADED_DATA'
}

export function storeUploadData(uploadedData: SavedFiles) {
    return {
        type: actionTypes.SET_UPLOADED_DATA,
        payload: {
            uploadedData
        }
    }
}

function storeCrosswalk(persistedCrosswalk: PersistedCrosswalk) {
    return {
        type: actionTypes.SET_EXISTING_CROSSWALK,
        payload: {
            persistedCrosswalk
        }
    }
}

export function storeExistingSurvey(mode: string, surveyQuestions: Question[]) {
    return {
        type: actionTypes.SET_EXISTING_SURVEY,
        payload: {
            mode,
            surveyQuestions
        }
    }
}

export const downloadCrosswalk = (project: ExistingProject) => {
    return async function (dispatch: Dispatch<any>, getState: () => CombinedState<StoreState>) {
        const { accessToken, isAuthenticated } = getState().authState;

        if (!isAuthenticated) {
            throw Error('Cannot download crosswalk for unauthenticated user.');
        }

        dispatch(storeCurrentProject(project));

        // If we haven't uploaded a project yet, we'll just continue on without the files since they don't exist
        const response = await getCrosswalk(project.id, accessToken)
        if (response === null || response.crosswalk === null) {
            return dispatch(openFileUpload());
        }
        
        const {crosswalk, deleted} = response;

        dispatch(storeCrosswalk(crosswalk));
        dispatch(storeDeleted(deleted || {}));

        // TODO this may be broken
        const existingModes = getExistingModes(crosswalk);

        await Promise.all(existingModes.map(getAndSaveSurveyQuestions))

        dispatch(openFileUpload());

        async function getAndSaveSurveyQuestions(mode: string) {
            const surveyFileXml = await getSurveyFile(project.id, mode, accessToken);
            const surveyQuestions = await parseSurveyFile(mode, surveyFileXml);
            dispatch(storeExistingSurvey(mode, surveyQuestions))
        }
    }
}

export function uploadSurvey(surveyName: string, surveyFile: Blob) {
    return async function (_: any, getState: () => CombinedState<StoreState>) {
        const { accessToken, isAuthenticated } = getState().authState;

        if (!isAuthenticated) {
            throw Error('Cannot upload surveys for unathenticated user');
        }

        const state = getState();
        if (!state.app.currentProject) {
            throw Error('Cannot upload surveys without a current project');
        }

        const surveyDataAsXml = await readBlobAsText(surveyFile);

        await saveSurveyFile(state.app.currentProject.id, surveyName, surveyDataAsXml, accessToken);
    }
} 

function getExistingModes(crosswalk: PersistedCrosswalk) {
    return Object.keys(crosswalk[0]).filter(key => !illegalModeNames.includes(key));
}
