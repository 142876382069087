type AppSettings = Readonly<{
    api: Readonly<{
        endpoint: string
    }>,
    oidc: Readonly<{
        url: string,
        clientId: string,
        issuer: string,
        redirectUri: string,
        scopes: string[],
        pkce: boolean,
        disableHttpsCheck: boolean,
    }>,
    app: Readonly<{
        includeDeletedQuestionsInMap: boolean
    }>
}>;

export const settings: AppSettings = {
    api: {
        endpoint: process.env.REACT_APP_API_ENDPOINT as string
    },
    oidc: {
        url: process.env.REACT_APP_OKTA_DOMAIN as string,
        clientId: process.env.REACT_APP_CLIENT_ID as string,
        issuer: process.env.REACT_APP_OIDC_ISSUER as string,
        redirectUri: window.location.origin + '/implicit/callback',
        scopes: ['openid', 'profile', 'email'],
        pkce: true,
        disableHttpsCheck: !!process.env.REACT_APP_DISABLE_HTTPS_CHECK,
    },
    app: {
        includeDeletedQuestionsInMap: false
    }
}