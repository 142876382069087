import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import styles from './download.styles';

class Download extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  downloadMap(){
    const { maptext } = this.props;
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(maptext));
    element.setAttribute('download', 'stackmap.txt');

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();
  }

  downloadData(){
    const { datatext } = this.props;
    var datablob = new Blob([datatext], {encoding:"UTF-8", type:"text/plain;charset=UTF-8"});
    var element = document.createElement('a');
    element.download = 'stackdata.asc';
    element.href = window.URL.createObjectURL(datablob);
    document.body.appendChild(element);

    element.click();
  }

  render() {
    const { stacked } = this.props;
  
    return(
      <div style={styles.buttonContainer}>
        {
          stacked ? (
            <div>
              <Button style={styles.button} variant="contained" color="default" value='Download Map' onClick={()=>this.downloadMap()}> Download Map </Button>
              <Button style={styles.button} variant="contained" color="default" value='Download Data' onClick={() => this.downloadData()}> Download Data </Button>
            </div>
          ) : (
            <span>Please select 'Stack Data' to download files</span>
          )
        }
      </div>
    )
  }
}

export default connect(
  (state) => ({
    datatext: state.crosswalk.datatext,
    maptext: state.crosswalk.maptext,
    stacked: state.crosswalk.stacked
  }),
  { }
)(Download);