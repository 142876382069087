import { createReducer } from '../common/redux.helpers';
import { ExistingProject } from '../crosswalk/crosswalk.types';
import { AppActions } from './app.actions';

export type AppState = {
    existingProjects: ExistingProject[];
    currentProject: ExistingProject | null;
    createProjectErrorMessage: string | null;
}

const initialState: AppState = {
    existingProjects: [],
    currentProject: null,
    createProjectErrorMessage: null,
};

const appReducer = createReducer<AppState, AppActions>(initialState,
    {
        'SET_AVAILABLE_PROJECTS': (state, action) => ({
            ...state,
            existingProjects: action.payload.projects
        }),
        'SET_CURRENT_PROJECT': (state, {payload}) => ({
            ...state,
            currentProject: payload.project
        }),
        'CREATE_PROJECT_ERROR': (state, {payload}) => ({
            ...state,
            createProjectErrorMessage: payload.message
        }),
    }
);

export default appReducer;