import { List, ListItem, ListItemText, MenuItem, Select, TextField } from '@material-ui/core';
import { Add, CheckBox, Cancel } from '@material-ui/icons';
import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateResponseMapping, addPunch } from './crosswalk-actions';
import { StoreState } from '../common/redux.store';
import { isPlaceholder } from './crosswalk-helper';
import { Response, MappedQuestion } from './crosswalk.types';

type DropDownValue = {
    questionId: string;
    questionMode: string;
    responseId: number;
    text: string;
}

type CrosswalkResponseDropdownProps = {
    rowIndex: number;
    mode: string;
    response: Response,
    dropDownValues: DropDownValue[]
}

function CrosswalkResponseDropdown({ rowIndex, mode, response, dropDownValues }: CrosswalkResponseDropdownProps) {

    const dispatch = useDispatch();

    const options = dropDownValues.map((entry, index) => (
        <MenuItem key={index} value={index}>{entry.text}</MenuItem>
    ))

    const value = dropDownValues.findIndex(v => v.responseId === response.mapping?.mappedResponseId);

    return (
        <Select
            onChange={handleChange}
            value={value === -1 ? '' : value}>
            {options}
        </Select>
    )

    function handleChange(event) {
        let dropDownSelected = dropDownValues[event.target.value];
        dispatch(updateResponseMapping(rowIndex, mode, response.id, dropDownSelected.responseId))
    }
}

export function CrosswalkQuestionResponses({
    rowIndex,
    mode,
    question,
    reMeasure
}: {
    rowIndex?: number,
    mode?: string,
    question: MappedQuestion,
    reMeasure: () => void
}) {
    const [showAddNewPunch, setShowAddNewPunch] = useState(false);
    const [newPunchId, setNewPunchId] = useState<number | null>(null);
    const [newPunchText, setNewPunchText] = useState('');
    const [error, setError] = useState('');

    const crosswalk = useSelector((s: StoreState) => s.crosswalk.crosswalkRowAssociation);
    const masterMode = useSelector((s: StoreState) => s.crosswalk.masterSurvey);

    const dispatch = useDispatch();

    // ReMeasure the cells when actions happen that can chagne size
    useEffect(reMeasure, [error, showAddNewPunch, question.responses.length])

    const dropDownValues =
        useMemo(() => {
            if (rowIndex === undefined) {
                return null;
            }

            const question = crosswalk[rowIndex].find(q => q.mode === masterMode);
            if (isPlaceholder(question)) {
                return null;
            }

            return question.responses.map(r => ({
                questionId: question.id,
                questionMode: question.mode,
                responseId: r.id,
                text: `${masterMode}: ${r.id} - ${r.text}`
            }));

        }, [crosswalk, masterMode, rowIndex]);

    const canAddPunch = dropDownValues !== null;
    const showResponses = masterMode !== question.mode && dropDownValues !== null;

    return (
        <div className="response-list">
            <List>
                {renderResponses()}

                {canAddPunch && !showAddNewPunch && getAddPunchElement()}
                {canAddPunch && showAddNewPunch && getFillPunchElement()}
                {error && <p className="punch-error">{error}</p>}
            </List>
        </div>
    )

    function renderResponses() {
        return question.responses.map((response, index) => {
            return (
                <ListItem key={index} className="response-item">
                    <ListItemText primary={`${response.id}: ${response.text}`} />
                    {
                        showResponses && 
                            <CrosswalkResponseDropdown
                                rowIndex={rowIndex as number}
                                mode={mode as string}
                                response={response}
                                dropDownValues={dropDownValues as DropDownValue[]}
                            />
                    }
                </ListItem>
            )
        })
    }

    function modifyShowAddNewPunch() {

        if (showAddNewPunch) {
            setShowAddNewPunch(false);
        }
        else {
            setShowAddNewPunch(true)
        }
    }

    function handleNewPunchId(possibleId: string) {

        const id = parseInt(possibleId, 10)
        if (isNaN(id)) {
            return setError('Punch Value must be a number.');
        }

        if (possibleId.trim().length !== id.toString().length) {
            return setError('Punch Value must be a number.');
        }

        setError('');
        setNewPunchId(id);
    }

    function handleSubmit() {

        if (newPunchId === null) {
            return setError('Please specify a Punch Value')
        }

        if (newPunchText === null) {
            return setError('Please specify a Punch Test')
        }

        if (question.responses.some(r => r.id === newPunchId)) {
            return setError('Punch Value must be unique.')
        }

        if (question.responses.some(r => r.text.trim() === newPunchText)) {
            return setError('Punch Text must be unique.')
        }

        dispatch(addPunch(rowIndex, mode, newPunchId, newPunchText))
        setNewPunchId(null);
        setNewPunchText('');
        setShowAddNewPunch(false);
        setError('');
    }

    function handleCancel() {
        setNewPunchId(null);
        setNewPunchText('');
        setShowAddNewPunch(false);
        setError('');
    }

    function getAddPunchElement() {
        return <ListItem key="add-punch" className="response-item add-punch" onClick={modifyShowAddNewPunch}>
            <Add color="primary" style={{ fontSize: '0.65rem' }} />
            <ListItemText primary="Add New Punch" />
        </ListItem>
    }

    function getFillPunchElement() {
        return <ListItem key="fill-punch" className="response-item fill-punch">
            <TextField
                size="small"
                id={`punch-value-${mode}-${rowIndex}`}
                label="Punch Value"
                required
                onChange={e => handleNewPunchId(e.target.value)} />
            <TextField
                size="small"
                id={`punch-text-${mode}-${rowIndex}`}
                label="Punch Text"
                required
                onChange={e => setNewPunchText(e.target.value.trim())}
                onSubmit={handleSubmit} />
            <CheckBox color="primary" onClick={handleSubmit} />
            <Cancel color="primary" onClick={handleCancel} />
        </ListItem>
    }
}