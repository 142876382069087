import React from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#009ADA'},
    secondary: { main: '#009ADA'},
    type: 'light'
  },
  typography: { useNextVariants: true }
});

const DataStackerAppThemeProvider = ({children}) => (
  <MuiThemeProvider theme={theme}>
    {children}
  </MuiThemeProvider>
)

export default DataStackerAppThemeProvider;