export const actionTypes = {
  SET_AUTH: 'SET_AUTH',
};

export const setAuth = (authState) => {
  return {
    type: actionTypes.SET_AUTH,
    payload: {
      authState
    }
  };
}