const styles = {
  form: {
    paddingRight: '2em',
    width: '225px',
  },
  uploadBox: {
    margin: '10px',
  },
  formCard: {
    padding: '10px 40px 20px'
  },
  mode: {
    marginBottom: '5px'
  },
  button: {
    float: 'right'
  },
  rowContainer: {
    display: 'flex',
    width: "100%",
    overflowX: "auto",
    paddingBottom: "18px"
  },
  container: {
    width: '100%'
  },
  stackButton: {
    paddingTop: '15px',
    justifyContent: 'center',
    display: 'flex'
  },
  errorText: {
    color: 'red',
    fontSize: 'small'
  },
  inputBoxes: {
    paddingTop: '10px',
    paddingBottom: '10px'
  },
  disclaimer: {
    padding: '10px'
  },
  sampleVarUpload: {
    marginLeft: '30px',
    marginBottom: '10px'
  },
  project: {
    padding: '10px' 
  }
};

export default styles;