import { Button, IconButton, Typography, CircularProgress } from '@material-ui/core';
import QueueIcon from '@material-ui/icons/Queue';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMasterSurvey, storeIsCrosswalkSaving } from '../crosswalk/crosswalk-actions';
import { uploadSurvey, storeUploadData } from './file-upload.actions';
import SurveyUploadBox, { SavedFiles } from './survey-upload-box.component';
import styles from './survey-upload.styles';
import { getExistingSources } from './file-upload.selectors';
import { getCurrentProjectName } from '../app/app.selectors';
import { openBuildCrosswalk } from '../common/expansion-panel/expansion-panel.actions';
import { isCrosswalkSaving } from '../common/redux/redux.selectors';
import { delay } from '../common/react.helpers';
import { buildCrosswalk } from '../crosswalk/crosswalk.builder';

type KVP = {
    key: string;
    value: string;
}

export default function SurveyUploadRow() {

    const projectName = useSelector(getCurrentProjectName);
    const existingSources = useSelector(getExistingSources);
    const isSaving = useSelector(isCrosswalkSaving);

    const twoExistingSources = useCallback(minTwo, [existingSources]);

    const [sources, setSources] = useState<KVP[]>(twoExistingSources(existingSources));
    const [surveys, setSurveys] = useState<SavedFiles[]>([]);

    useEffect(() => {
        setSources(twoExistingSources(existingSources))
    }, [existingSources, twoExistingSources])

    const dispatch = useDispatch();

    return (
        <div style={styles.container as React.CSSProperties}>
            <div style={styles.project as React.CSSProperties}>
                <Typography variant="h6" display="inline">Project:</Typography>
                <Typography color="primary" variant="h6" display="inline"> {projectName}</Typography>
            </div>
            <div id="rowContainer" style={styles.rowContainer as React.CSSProperties}>
                {generateSurveyBoxes()}
                <div>
                    <IconButton onClick={addSurvey}>
                        <QueueIcon />
                    </IconButton>
                </div>
            </div>
            <div style={styles.stackButton}>
                {buildAction()}
            </div>
        </div>
    );

    function generateSurveyBoxes() {
        return sources.map((source, i) => {
            const otherSources = sources.filter(s => s.key !== source.key);
            const existingMode = existingSources && existingSources[i]

            return (
                <div key={source.key} className="boxContainer">
                    <SurveyUploadBox
                        index={i}
                        existingMode={existingMode}
                        otherSources={otherSources.map(s => s.value)}
                        onSave={saveBox}
                        setSourceName={setSourceName(i)}
                    />
                    {!existingMode ?
                        (<IconButton className="remove-upload-box-button" onClick={() => removeSurvey(i)}>
                            <HighlightOffIcon />
                        </IconButton>)
                        : ''}
                </div>
            )
        })
    }

    function buildAction() {

        if (sources.length < 2) {
            return <span>You must have at least 2 Sources to continue.</span>
        }

        if (sources.length !== surveys.filter(s => !!s).length) {
            return <span>Save all sources to continue.</span>;
        }

        return <Button
            variant="outlined"
            size="large"
            onClick={() => persistAndContinue()}
            disabled={isSaving}
        >
            Continue
            {
                isSaving &&
                <CircularProgress size={24} className="button-spinner"/>
            }
        </Button>
    }

    async function persistAndContinue() {

        dispatch(storeIsCrosswalkSaving(true));
        await delay(100);

        // Store all of the uploaded data for later
        surveys.forEach(s => dispatch(storeUploadData(s)))

        // Upload any new surveys
        const saveSurveyPromises = surveys.filter(s => !!s.surveyFile).map(s => dispatch(uploadSurvey(s.mode, s.surveyFile)))
        await Promise.all(saveSurveyPromises);

        dispatch(setMasterSurvey(sources[0].value));
        dispatch(buildCrosswalk());
        dispatch(openBuildCrosswalk());
    }

    function setSourceName(index: number) {
        return (sourceName: string) => {
            setSources(sources.map((s, i) => ({ key: s.key, value: i === index ? sourceName : s.value })));
        }
    }

    function saveBox(files: SavedFiles) {

        const newSurveys = [...surveys];
        newSurveys[files.index] = files;

        const newSources = [...sources];
        newSources[files.index].value = files.mode;

        setSurveys(newSurveys);
        setSources(newSources);
    }

    function addSurvey() {
        setSources([...sources, { key: generateKey(), value: '' }]);
    }

    function removeSurvey(index: number) {
        const newSources = sources.filter((s, i) => i !== index);
        setSources(newSources);
    }

    function minTwo(sources: string[] | null): KVP[] {
        const s: KVP[] = [];
        sources = sources || [];
        for (let index = 0; index < Math.max(sources.length, 2); index++) {
            s[index] = {
                key: generateKey(),
                value: sources[index] || ''
            };
        }
        return s;
    }

    function generateKey() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
            return v.toString(16);
        });
    }
}