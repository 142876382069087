import { createReducer } from 'async-redux-helpers';
import { actionTypes as actions } from './crosswalk-actions';
import { buildEmptyRow, placeholder } from './crosswalk-helper';

const crosswalkReducer = createReducer(
    {
        surveyQuestions: [],
        deletedCrosswalkQuestions: {},
        crosswalkRowAssociation: [],
        sorted: false,
        shouldShowAllResponses: false,
        stacked: false,
        masterSurvey: null
    },
    {
        [actions.SORT_CROSSWALK_QUESTIONS]: (state = {}, action) => ({
            ...state,
            sorted: true,
            crosswalkRowAssociation: cleanCrosswalk(action.payload.crosswalkRowAssociation)
        }),
        [actions.BUILD_CROSSWALK]: (state = {}, action) => ({
            ...state,
            datatext: action.payload.datatext,
            maptext: action.payload.maptext,
            stacked: true
        }),
        [actions.TOGGLE_REPONSE_IS_OPEN_ALL]: (state = {}, action) => updateAllIsResponseOpen(state, action.payload.isOpen),
        [actions.TOGGLE_REPONSE_IS_OPEN]: (state = {}, { payload }) => updateIsResponseOpen(state, payload),
        [actions.UNDELETE_QUESTION]: (state = {}, { payload }) => undeleteQuestion(state, payload.mode, payload.deletedIndex, payload.intoIndex),
        [actions.SWAP_QUESTIONS]: (state = {}, { payload }) => swapQuestions(state, payload.mode, payload.index1, payload.index2),
        [actions.SPLIT_ROW]: (state = {}, { payload }) => splitRow(state, payload.rowIndex),
        [actions.DELETE_QUESTION]: (state = {}, { payload }) => deleteQuestion(state, payload.rowIndex, payload.mode),
        [actions.UPDATE_RESPONSE_MAPPING]: (state = {}, { payload }) => updateResponseMapping(state, payload),
        [actions.ADD_PUNCH]: (state = {}, { payload }) => addPunch(state, payload),
        [actions.SET_MASTER_SURVEY]: (state = {}, { payload }) => setMasterSurvey(state, payload.surveyName),
        [actions.IS_CROSSWALK_SAVING]: (state = {}, { payload }) => ({
            ...state,
            isSaving: payload.isSaving
        }),
        [actions.SET_DELETED_CROSSWALK]: (state, { payload }) => ({
            ...state,
            deletedCrosswalkQuestions: payload.deletedCrosswalk
        }),
    }
);

function setMasterSurvey(state, surveyName) {

    const crosswalk = [...state.crosswalkRowAssociation];

    // If we have a crosswalk, we need to swap the positions for the new master survey
    if (crosswalk && crosswalk[0]) {
        const existingSurveyIndex = crosswalk[0].findIndex(x => x.mode === surveyName);

        return {
            ...state,
            masterSurvey: surveyName,
            crosswalkRowAssociation: crosswalk.map(row => {
                return row.map((r, i) => i === 0 ? row[existingSurveyIndex] : i === existingSurveyIndex ? row[0] : r)
            })
        }
    }

    return {
        ...state,
        masterSurvey: surveyName,
        crosswalkRowAssociation: cleanCrosswalk(crosswalk)
    }
}

function updateAllIsResponseOpen(state, isOpen) {
    const updatedCrosswalk = state.crosswalkRowAssociation.map(r => r.map(q => ({ ...q, isOpen: isOpen })))
    return ({
        ...state,
        crosswalkRowAssociation: cleanCrosswalk(updatedCrosswalk)
    });
}

function updateIsResponseOpen(state, { rowIndex, mode, isOpen }) {
    const updatedCrosswalk = state.crosswalkRowAssociation.map((r, i) => i !== rowIndex ? r : r.map(q => ({ ...q, isOpen: q.mode === mode ? isOpen : q.isOpen })));
    return ({
        ...state,
        crosswalkRowAssociation: cleanCrosswalk(updatedCrosswalk)
    });
}

function undeleteQuestion(state, mode, deletedIndex, intoIndex) {

    const crosswalk = state.crosswalkRowAssociation;
    const modes = crosswalk[0].map(q => q.mode);
    const columnIndex = crosswalk[0].findIndex(q => q.mode === mode);
    const addToEnd = intoIndex === undefined;
    intoIndex = addToEnd ? crosswalk.length + 1 : intoIndex;

    const deletedQuestion = state.deletedCrosswalkQuestions[mode][deletedIndex];
    if (!deletedQuestion) {
        throw Error('Could not find question to undelete');
    }

    const questionToOverwrite = (crosswalk[intoIndex] || []).find(q => q.mode === mode);
    if (questionToOverwrite != null && questionToOverwrite.id !== 'PLACEHOLDER') {
        throw Error('Cannot undelete question onto existing question');
    }

    const updatedCrosswalk = addToEnd
        ? [...state.crosswalkRowAssociation, buildEmptyRow(modes, deletedQuestion, columnIndex)]
        : state.crosswalkRowAssociation.map((r, i) => i !== intoIndex ? r : r.map(q => q === questionToOverwrite ? ({ ...deletedQuestion }) : q));
    const updatedDeletedQuestions = {
        ...state.deletedCrosswalkQuestions,
        [mode]: state.deletedCrosswalkQuestions[mode].filter(q => q !== deletedQuestion)
    }

    return ({
        ...state,
        crosswalkRowAssociation: cleanCrosswalk(updatedCrosswalk),
        deletedCrosswalkQuestions: updatedDeletedQuestions
    });
}

function swapQuestions(state, mode, index1, index2) {

    const modeQuestions = state.crosswalkRowAssociation.map(r => r.find(q => q.mode === mode));

    const q1 = modeQuestions[index1];
    const q2 = modeQuestions[index2];

    const updatedModeQuestions = modeQuestions.map((q, i) => i === index1 ? q2 : q)
        .map((q, i) => i === index2 ? q1 : q);

    const updatedCrosswalk = state.crosswalkRowAssociation.map((r, i) => r.map(q => q.mode === mode ? updatedModeQuestions[i] : q));

    return {
        ...state,
        crosswalkRowAssociation: cleanCrosswalk(updatedCrosswalk)
    };
}

function splitRow(state, rowIndex) {

    const crosswalk = state.crosswalkRowAssociation;
    const modes = crosswalk[0].map(q => q.mode);
    const row = crosswalk[rowIndex];

    const before = crosswalk.slice(0, rowIndex);
    const after = crosswalk.slice(rowIndex + 1);

    const rowSplit = row.map((q, i) => buildEmptyRow(modes, q, i));

    const updatedCrosswalk = before.concat(rowSplit).concat(after);

    return {
        ...state,
        crosswalkRowAssociation: cleanCrosswalk(updatedCrosswalk)
    }

}

function deleteQuestion(state, rowIndex, mode) {
    const crosswalk = state.crosswalkRowAssociation;
    const deletedQuestion = crosswalk[rowIndex].find(q => q.mode === mode);

    const updatedCrosswalk = crosswalk.map((r, i) => i !== rowIndex ? r : r.map(q => q.mode === mode ? placeholder(mode) : q))

    return {
        ...state,
        crosswalkRowAssociation: cleanCrosswalk(updatedCrosswalk),
        deletedCrosswalkQuestions: {
            ...state.deletedCrosswalkQuestions,
            [mode]: [...state.deletedCrosswalkQuestions[mode] || [], deletedQuestion]
        }
    };
}

function updateResponseMapping(state, { rowIndex, mode, nonMasterResponseId, masterResponseId }) {
    const crosswalk = state.crosswalkRowAssociation;

    const masterQ = crosswalk[rowIndex][0];
    const question = crosswalk[rowIndex].find(q => q.mode === mode);
    const masterR = masterQ.responses.find(r => r.id === masterResponseId);
    const response = question.responses.find(r => r.id === nonMasterResponseId);

    const updatedCrosswalk = crosswalk.map((row, i) => i !== rowIndex ? row : row.map(q => q.mode !== mode ? q : ({
        ...question,
        responses: q.responses.map(resp => resp !== response ? resp : ({
            ...response,
            mapping: {
                questionIdMappingTo: masterQ.id,
                questionModeMappingTo: masterQ.mode,
                mappedResponseId: masterR.id
            }
        }))
    })))

    return {
        ...state,
        crosswalkRowAssociation: cleanCrosswalk(updatedCrosswalk)
    }
}

function addPunch(state, { rowIndex, mode, newPunchId, newPunchText }) {

    const crosswalk = state.crosswalkRowAssociation;

    const newResponse = {
        id: newPunchId,
        text: newPunchText,
        mapping: {}
    };

    const updatedCrosswalk = crosswalk.map((r, i) => i !== rowIndex ? r : r.map(q => q.mode !== mode ? q : ({
        ...q,
        responses: [...q.responses, newResponse]
    })))

    return {
        ...state,
        crosswalkRowAssociation: cleanCrosswalk(updatedCrosswalk)
    }
}

function cleanCrosswalk(crosswalk) {
    return crosswalk.filter(qp => qp.some(q => q?.id !== 'PLACEHOLDER'));
}

export default crosswalkReducer;
