import parser from 'fast-xml-parser';
import Papa from 'papaparse';
import {readBlobAsText} from '../common/react.helpers';
import { DataMap, Question, Response } from './crosswalk.types';

export async function readResults(dataFile: File): Promise<string> {

    if (!dataFile) {
        throw Error('Please upload a data file.')
    }

    const contents = await readBlobAsText(dataFile);
    if (contents.startsWith('varname') || contents.startsWith('"varname"')) {
        throw Error('The file uploaded as a data file is a data map.');
    }

    return contents;
}

export async function readDataMap(mapFile: File): Promise<DataMap[]> {
    const dataMap = await readBlobAsCsv(mapFile);
    return dataMap.map(m => ({
        ...m,
        varname: m.varname?.toUpperCase(),
        nummult: m.nummult || '1'
    }))
}

export async function parseNewSurveyFile(mode: string, surveyFile: File): Promise<Question[]> {
    const surveyDataAsXml = await readBlobAsText(surveyFile);
    return parseSurveyFileFile(mode, surveyDataAsXml);
};

export async function parseSurveyFile(mode: string, surveyDataAsXml: string): Promise<Question[]> {
    return parseSurveyFileFile(mode, surveyDataAsXml);
};

const parseSurveyFileFile = (mode: string, surveyDataAsXml: string) => {
    let isValidXml = parser.validate(surveyDataAsXml);
    if (isValidXml !== true) {
        throw Error(`The survey file is not valid xml. On line ${isValidXml.err.line}, the error is: ${isValidXml.err.msg}`);
    }

    let surveyDataAsJson = parser.parse(surveyDataAsXml, { ignoreAttributes: false });
    if (surveyDataAsJson.sss) {
        return parseTripSSurvey(surveyDataAsJson);
    }
    else if (surveyDataAsJson.WebSurvey) {
        throw Error('The Web Survey format is not currently supported.');
        // return parseWebSurvey(mode, surveyDataAsJson);
    }
    else {
        return parseGsgSurvey(surveyDataAsJson)
    }
}

function parseTripSSurvey(webSurveySSSData) {
    let questionData = webSurveySSSData.sss.survey.record.variable;

    return questionData.map(q => ({
        ...q,
        name: stripQuotes(q.name).toUpperCase()
    })).reduce((acc, question) => {
        let questionId = question.name;
        if (questionId === 'conditions') {
            let conditions = renderBinaryConditional(question);
            return acc.concat(conditions);
        }
        let start = question.position['@_start'];
        let finish = question.position['@_finish'];
        return acc.concat({
            id: stripQuotes(questionId),
            text: getTextValue(question.label, questionId),
            location: start,
            length: (finish - start + 1),
            numMult: calculateNumMult(question),
            responses: ((question.values && question.values.value && parseTripSResponse(question.values.value)) || [])
        })
    }, [])
};

function renderBinaryConditional(conditional) {
    let startingLocation = conditional.position['@_start'];
    return conditional.values.value.map((condition, index) => {
        let name = condition['#text'];
        let id;
        if (name.indexOf('hasMarker') !== -1) {
            id = `C${index+1}_MAR`;
        }
        else {
            id = `C${index+1}_${name.substring(0,3)}`.toUpperCase()
        }
        return ({
            id: stripQuotes(id),
            text: getTextValue(name, id),
            location: (startingLocation + index),
            length: 1,
            numMult: 1,
            responses: [{
                id: 1,
                text: 'Yes',
                mapping: {}
            }, {
                id: 0,
                text: 'No',
                mapping: {}
            }]
        })
    })
}

function calculateNumMult(question) {
    const numMult = question['@_type'] !== 'multiple' ? 1 : ((question.spread && question.spread['@_subfields']) || 1);
    return numMult;
}

function stripQuotes(text: string) {
    // Remove the quotes from the front and back of the value
    return text.toString().replace(/^"|"$/g, '');
}

function getTextValue(text, id) {
    return (text === "") ? id : text;
}

function parseTripSResponse(responses) {
    if (!Array.isArray(responses)) {
        responses = [responses]
    }
    return responses.map((response, ) => {
        let id = parseInt(response['@_code'], 10);
        return ({
            id: stripQuotes(id.toString()),
            text: getTextValue(response['#text'], id),
            mapping: {}
        });
    });
};

// function parseWebSurvey(mode: string, webSurveyData) {
//     let questionArray: Question[] = [];
//     let questionData = webSurveyData.WebSurvey.questions.WebQuestion;
//     questionData.forEach(question => {
//         if (question.questions) {
//             let layeredQuestion = question.questions.WebQuestion;
//             let layeredQuestionArray = [];
//             layeredQuestionArray = layeredQuestion.map(subQuestion => {
//                 let id = subQuestion.varname;
//                 return ({
//                     id: stripQuotes(id),
//                     text: getTextValue(subQuestion.text, id),
//                     location: question.location,
//                     length: question.length,
//                     numMult: question['num_responses'],
//                     responses: (subQuestion.responses && subQuestion.responses.WebResponse && buildWebSurveyResponseObject(subQuestion.responses.WebResponse)) || []
//                 });
//             });
//             questionArray = questionArray.concat(layeredQuestionArray);
//         }
//         else {
//             let id = question.varname;
//             questionArray.push({
//                 id: stripQuotes(id),
//                 text: getTextValue(question.text, id),
//                 location: question.location,
//                 length: question.length,
//                 numMult: question['num_responses'],
//                 responses: (question.responses && question.responses.WebResponse && buildWebSurveyResponseObject(question.responses.WebResponse)) || [],
//                 mode: mode
//             });
//         }
//     });
//     return questionArray;
// };

// function buildWebSurveyResponseObject(responses) {
//     if (responses.constructor.name === "Object") {
//         return [{
//             id: parseInt(responses.code, 10),
//             text: responses.text,
//             mapping: {}
//         }];
//     }
//     else {
//         let responseArray: Response[] = [];
//         responses.forEach(response => {
//             responseArray.push({
//                 id: parseInt(response.code, 10),
//                 text: response.text,
//                 mapping: {}
//             });
//         });
//         return responseArray;
//     }
// };

function parseGsgSurvey(gsgData: any) {

    const questions = (gsgData.Survey.Questions.Question as any[]).filter(q => !!q.varname);

    const errors = questions.filter(q => !validNumMultDisivibleLength(q.numMult, q.length))
        .map(q => `Question ${q.varname} - length (${q.length}) not divisible by nummult (${q.numMult}).`);

    if (errors.length) {
        throw Error(errors.reduce((p, c) => `${p}\n${c}`));
    }

    return questions.filter(q => q.length !== -1 && q.location !== -1)
        .map(q => ({
            ...q,
            'varname': stripQuotes(q.varname).toUpperCase()
        }))
        .reduce((all, current) => {
            const sameIdIndex = all.findIndex(q => current.varname === q.varname)
            
            // If there's no duplicate, add the current and move on
            if (sameIdIndex === -1) {
                all.push(current);
                return all;
            }

            // If the duplicate name is inSurv, use it instead
            const duplicate = all[sameIdIndex];
            if (duplicate.inSurv === true) {
                all[sameIdIndex] = current;
                return all;
            }

            // If there's a duplicate, but it's not inSurv, use the existing
            return all;
        }, [])
        .map(q => ({
            id: q.varname,
            text: getTextValue(q.text, q.varname),
            numMult: q.numMult,
            location: q.location,
            length: q.length,
            responses: buildGsgResponseObject(q.Responses.Response)
        }));
};

function buildGsgResponseObject(responses) {
    if (!responses) {
        return [];
    }
    else if (responses.constructor.name === "Object") {
        return [{
            id: parseInt(responses.punch, 10),
            text: responses.text,
            mapping: {}
        }];
    }
    else {
        let responseObjArray: Response[] = [];
        responses.forEach(response => {
            responseObjArray.push({
                id: parseInt(response.punch, 10),
                text: response.text,
                mapping: {}
            });
        });
        return responseObjArray;
    }
};

function validNumMultDisivibleLength(numMult, length) {
    return (((length === 0) && (numMult === 0)) || (length % numMult === 0));
}

function textToArray(text: string, hasHeader: boolean) {
    return Papa.parse(text, {
        header: hasHeader,
        skipEmptyLines: true
    });
}

function readBlobAsCsv(blob: Blob): Promise<DataMap[]> {
    let reader = new FileReader();
    return new Promise(
        (resolve, reject) => {
            reader.onload = event => resolve(textToArray(event.target?.result as string, true).data as DataMap[]);
            reader.onerror = event => reject('Error reading file');
            reader.readAsText(blob);
        }
    )
}
