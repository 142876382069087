import { settings } from "../../appSettings"
import { DeletedCrosswalk, ExistingProject, PersistedCrosswalk } from "../crosswalk/crosswalk.types";

export type CrosswalkJson = {
    projectId: string;
    projectName: string;
    crosswalk: string
    deleted: string
};

export type SurveyXml = {
    projectId: string;
    projectName: string;
    sourceName: string;
    xml: string
};

function getHeaders(accessToken: string){
    return {
        Authorization: `Bearer ${accessToken}`,
    };
}

function fetchWithToken(info: RequestInfo, accessToken: string) {
    const promise = fetch(info, {
        headers: getHeaders(accessToken),
    });

    return promise.then(response => {
        if (response.status === 401) {
            window.location.reload()
        }

        return response;
    })
}

export async function getProjects(searchName: string, accessToken: string): Promise<ExistingProject[]> {

    const response = await fetchWithToken(settings.api.endpoint + '/projects?searchName=' + searchName, accessToken);
    
    const projectsJson = await response.json() as any[];

    return projectsJson.map(p => ({
        isMine: p.isMine,
        id: p.id,
        lastUpdate: new Date(p.lastUpdated),
        name: p.name
    }));
}

export async function addProject(projectName: string, accessToken: string): Promise<string> {
    const response = await fetch(`${settings.api.endpoint}/projects?projectName=${projectName}`, { //`
        method: 'POST',
        headers: {
            ...getHeaders(accessToken),
            'Content-Type': 'application/json'
        },
    });

    const text = await response.text();
    if (!response.ok) {
        throw Error(text);
    }

    return text;
}

type GetCrosswalk = {
    crosswalk: PersistedCrosswalk | null
    deleted: DeletedCrosswalk | null
}

export async function getCrosswalk(projectId: string, accessToken: string): Promise<GetCrosswalk | null> {
    const response = await fetchWithToken(settings.api.endpoint + '/projects/' + projectId + '/crosswalk', accessToken);
    
    if (response.status === 404) {
        return null;
    }

    if (!response.ok) {
        throw Error(await response.text());
    }

    const crosswalk = await response.json() as CrosswalkJson;
    return {
        crosswalk: JSON.parse(crosswalk.crosswalk),
        deleted: JSON.parse(crosswalk.deleted)
    }
}

export async function getSurveyFile(projectId: string, source: string, accessToken: string): Promise<string> {
    const response = await fetchWithToken(`${settings.api.endpoint}/projects/${projectId}/surveys/${source}`, accessToken); //`
    const survey = await response.json() as SurveyXml;

    return survey.xml;
}

export async function saveSurveyFile(projectId: string, source: string, surveyXml: string, accessToken: string) {
    await fetch(`${settings.api.endpoint}/projects/${projectId}/surveys/${source}`, { //`
        method: 'PUT',
        body: JSON.stringify({
            xml: surveyXml
        }),
        headers: {
            ...getHeaders(accessToken),
            'Content-Type': 'application/json'
        },
    })
}

export async function uploadCrosswalk(projectId: string, crosswalk: PersistedCrosswalk, deletedCrosswalk: DeletedCrosswalk, accessToken: string) {
    await fetch(`${settings.api.endpoint}/projects/${projectId}/crosswalk`, { //`
        method: 'PUT',
        body: JSON.stringify({
            crosswalk: JSON.stringify(crosswalk),
            deleted: JSON.stringify(deletedCrosswalk)
        }),
        headers: {
            ...getHeaders(accessToken),
            'Content-Type': 'application/json'
        },
    })
}