import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../../authentication/authentication.actions';
import ExpansionPanelComponent from '../../common/expansion-panel/expansion-panel.component';
import CrosswalkProjectDownload from '../../crosswalk-persistence/crosswalk-project-download.component';
import { BuildCrosswalk } from '../../crosswalk/crosswalk';
import Download from '../../download/download.component';
import SurveryUploadRow from '../../survey-upload/survey-upload-row.component';
import DataStackerNavigation from './data-stacker-navigation.component';
import styles from './data-stacker.styles';
import { isCrosswalkSorted } from '../../common/redux/redux.selectors';
import { useOktaAuth } from '@okta/okta-react';

const DataStackerPage = () => {

    const {authState} = useOktaAuth();
    const dispatch = useDispatch();
    
    // dispatch(setAuth({accessToken: '', isAuthenticated: true}))
    useEffect(() => {
        dispatch(setAuth(authState));
    }, [authState, dispatch]);

    const isLocked = useSelector(isCrosswalkSorted);

    if (!authState.isAuthenticated) {
        return <></>;
    }

    return (
        <div id='data-stacker-container' style={styles.background}>
            <DataStackerNavigation />
            <ExpansionPanelComponent
                isLocked={isLocked}
                panelId="SELECT_PROJECT"
                expansionPanelComponent={<CrosswalkProjectDownload />}
            />
            <ExpansionPanelComponent
                isLocked={isLocked}
                panelId="UPLOAD_SURVEY"
                expansionPanelComponent={<SurveryUploadRow />}
            />
            <ExpansionPanelComponent
                panelId="BUILD"
                expansionPanelComponent={<BuildCrosswalk />}
            />
            <ExpansionPanelComponent
                panelId="DOWNLOAD"
                expansionPanelComponent={<Download />}
            />
        </div>
    )
}

export default DataStackerPage;