import React from 'react';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography, Button, CircularProgress } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles from './expansion-panel.styles';
import { StoreState } from '../redux.store';
import { useSelector, useDispatch } from 'react-redux';
import { setPanel } from './expansion-panel.actions';
import { PanelId, panelNames } from './expansion-panel.types';
import { isCrosswalkSorted, isCrosswalkSaving } from '../redux/redux.selectors';
import { stackData } from '../../crosswalk/crosswalk-actions';

type ExpansionPanelComponentType = {
    panelId: PanelId,
    expansionPanelComponent: JSX.Element,
    isLocked?: boolean
}

const ExpansionPanelComponent = ({ panelId, expansionPanelComponent, isLocked = false }: ExpansionPanelComponentType) => {

    const panelSummary = panelNames[panelId];
    const isExpanded = useSelector((s: StoreState) => s.panels[panelSummary]);
    const isSorted = useSelector(isCrosswalkSorted);
    const isSaving = useSelector(isCrosswalkSaving);

    const dispatch = useDispatch();

    return (
        <ExpansionPanel disabled={isLocked} className={buildClassName()} style={styles.expansionPanel} expanded={isExpanded} onChange={updateIsExpanded}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant='h6'>{panelSummary}</Typography>
                {
                    isSorted && panelId === 'BUILD' && isExpanded &&
                    <Button
                        variant="contained" 
                        color="primary" 
                        className="save-crosswalk-btn" 
                        onClick={save}
                        disabled={isSaving}
                    >
                        Save
                            {
                            isSaving &&
                            <CircularProgress size={24} className="button-spinner" />
                        }
                    </Button>
                }
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                {expansionPanelComponent}
            </ExpansionPanelDetails>
        </ExpansionPanel>
    )

    function buildClassName() {
        return `${panelSummary.toLowerCase().replace(/\s+/g, '-')}-panel`;
    }

    function updateIsExpanded(event: React.ChangeEvent<{}>, isExpanded: boolean) {
        if (!event.defaultPrevented) {
            event.preventDefault();
            dispatch(setPanel(panelId, isExpanded))
        }
    }

    function save(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        event.preventDefault();
        dispatch(stackData());
    }
}

export default ExpansionPanelComponent;