import React from 'react';
import DataStackerPage from './data-stacker/data-stacker.page.tsx';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { LoginCallback, Security, SecureRoute } from '@okta/okta-react';
import {settings} from '../../appSettings'

const App = () => {
  return (
    <Router>
      <Security {...settings.oidc}>
        <SecureRoute path="/" exact component={DataStackerPage} />
        {/* <Route path="/" exact component={DataStackerPage} /> */}
        <Route path="/implicit/callback" component={LoginCallback} />
      </Security>
    </Router>
  );
}

export default App;
