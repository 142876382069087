import React, { useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import { useSelector } from 'react-redux';
import { AutoSizer, CellMeasurer, List, GridCellRangeRenderer } from 'react-virtualized';
import { StoreState } from '../common/redux.store';
import { CrosswalkDeletedContainer } from './crosswalk-deleted-model';
import { CrosswalkHeader } from './crosswalk-header';
import { QuestionRow } from './crosswalk-question';
import { getCrosswalkModes } from '../common/redux/redux.selectors';
import { CrosswalkCache, crosswalkCellRangeRenderer, buildRowKey } from './crosswalk.virtual-helpers';

export function CrosswalkContainer() {

    const stackSources = useSelector(getCrosswalkModes);

    return (
        <div className="crosswalk">
            <div className="rows-full-width">
                <CrosswalkHeader stackSources={stackSources} />
                <CrossWalk />
                <CrosswalkDeletedContainer />
            </div>
        </div>
    )
}

export function CrossWalk() {

    const crosswalk = useSelector((s: StoreState) => s.crosswalk.crosswalkRowAssociation);


    const listRef = useRef<List>(null);
    const [cache] = useState(new CrosswalkCache({
        defaultHeight: 200,
        fixedWidth: true
    }));

    cache.setKeyMapper(buildRowKey(crosswalk))

    if (!crosswalk || crosswalk.length === 0)
        return null;

    return (
        <DndProvider backend={Backend}>
            <AutoSizer disableHeight>
                {({ width }) => (
                    <List
                        cellRangeRenderer={crosswalkCellRangeRenderer as GridCellRangeRenderer}
                        ref={listRef}
                        height={800}
                        width={width}
                        columnWidth={cache.columnWidth}
                        rowHeight={cache.rowHeight}
                        rowCount={crosswalk.length}
                        columnCount={crosswalk[0].length + 1}
                        deferredMeasurementCache={cache}
                        rowRenderer={cellMeasurerRenderer}
                    />
                )}
            </AutoSizer>
        </DndProvider>
    )

    function cellMeasurerRenderer({ index, key, parent, style }) {
        return (
            <CellMeasurer
                cache={cache}
                key={key}
                parent={parent}
                rowIndex={index}
            >
                {({ measure, registerChild }) => (
                    <div ref={registerChild as any} style={{ ...style, outline: 'none' }}>
                        <QuestionRow
                            row={crosswalk[index]}
                            rowIndex={index}
                            reMeasure={measure}
                        />
                    </div>
                )}
            </CellMeasurer>
        );
    }
}
