import { Card, CardContent, Grid, TextField, InputAdornment } from '@material-ui/core';
import { Autocomplete, RenderInputParams } from '@material-ui/lab';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { StoreState } from '../common/redux.store';
import { MappedQuestion } from './crosswalk.types';
import { Search } from '@material-ui/icons';
import { undeleteQuestion, swapQuestions } from './crosswalk-actions';

export function Placeholder({ rowIndex, mode }: { rowIndex: number, mode: string }) {

    const questions = useSelector((s: StoreState) => s.crosswalk.crosswalkRowAssociation.map(r => r.find(q => q.mode === mode))) as MappedQuestion[];
    const notPlaceholders = questions.filter(q => q !== undefined && q.id !== 'PLACEHOLDER') as MappedQuestion[];
    const deletedQuestions = useSelector((s: StoreState) => s.crosswalk.deletedCrosswalkQuestions)[mode] || [];
    const dropdownOptions = notPlaceholders.concat(deletedQuestions).filter(p => p !== undefined);

    const dispatch = useDispatch();

    return (
        <Card>
            <CardContent className="placeholder-content">
                <Grid container justify="center">
                    <Grid item>
                        <Autocomplete
                            autoHighlight={true}
                            options={dropdownOptions}
                            getOptionLabel={renderOption}
                            renderOption={renderOption}
                            style={{ width: 300 }}
                            onChange={replaceWithQuestion}
                            renderInput={renderSearchInput} />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )

    function renderOption(question: MappedQuestion) {
        let option: string;
        if (question.text?.startsWith(question.id)) {
            option = question.text.substring(0, 40);
        } else if (!question.text) {
            option = question.id
        } else {
            option = `${question.id}: ${question.text}`;
        }

        return option.length > 40
            ? option.substring(0, 40) + '...'
            : option;
    }

    function replaceWithQuestion(_, question: MappedQuestion | null) {
        if (question === null) {
            return;
        }

        replacePlaceholder(rowIndex, question);
    }

    function renderSearchInput(params: RenderInputParams) {
        return <TextField
            {...params}
            variant="outlined"
            label="Search"
            InputProps={{
                ...params.InputProps,
                startAdornment: (<InputAdornment position="start"><Search /></InputAdornment>)
            }} />
    }

    
    function replacePlaceholder(rowIndex: number, foundQuestion: MappedQuestion) {

        const deletedQuestionIndex = deletedQuestions.findIndex(q => q.id === foundQuestion.id);
        const existingQuestionIndex = questions.findIndex(q => q.id === foundQuestion.id);

        if (deletedQuestionIndex === -1 && existingQuestionIndex === -1) {
            throw Error('Cannot find question to replace');
        }

        const action = deletedQuestionIndex === -1
            ? swapQuestions(mode, existingQuestionIndex, rowIndex)
            : undeleteQuestion(mode, deletedQuestionIndex, rowIndex);

        dispatch(action);
    }

}