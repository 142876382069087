import { PossibleMappedQuestion, PlaceholderType, MappedQuestion } from "./crosswalk.types";
 
export const illegalModeNames = ['Master', 'location', 'length', 'numMult'];

export function isPlaceholder(question: PossibleMappedQuestion | null | undefined): question is PlaceholderType {
    return question === null || question === undefined || question.id === 'PLACEHOLDER'
}

export function buildEmptyRow(modes: string[], question: MappedQuestion, index: number) {
    return Array(modes.length).fill(null).map((_, i) => i === index ? question : placeholder(modes[i]));
}

export function placeholder(mode: string): MappedQuestion {
    return {
        id: 'PLACEHOLDER',
        text: 'Placeholder',
        responses: [],
        mode: mode || '',
        isOpen: false,
        numMult: undefined,
        length: undefined,
        location: undefined
    };
}

type QuestionIsh = {
    text?: string;
    id?: string;
}

// Remove the question id from the front if it exists
export function getCleanText(question: QuestionIsh) {
    return question?.text?.replace(RegExp(`^${question?.id}. `), ''); //`
}

// Tests if the clean version of the text matches
export function isCleanTextMatch(question1: QuestionIsh, question2: QuestionIsh) {
    return getCleanText(question1)?.toLowerCase() === getCleanText(question2)?.toLowerCase();
}
