import { ChangeEvent, useState } from "react";

export type SelectChangeEvent = ChangeEvent<{ name?: string | undefined; value: unknown; }>;

export function readBlobAsText(blob: Blob): Promise<string> {
    let reader = new FileReader();
    return new Promise(
        (resolve, reject) => {
            reader.onload = event => resolve(event.target?.result as string);
            reader.onerror = event => reject('Error reading file.');
            reader.readAsText(blob);
        }
    )
}

export function delay(milliseconds: number) {
    return new Promise((resolve) => setTimeout(() => resolve(), milliseconds));
}

export function useAppendableState<T>(defValue: T[]): [T[], React.Dispatch<React.SetStateAction<T[]>>, React.Dispatch<T | T[]>] {
    const [state, setState] = useState(defValue);

    return [state, setState, appendToState];

    function appendToState(value: T | T[]) {
        if (Array.isArray(value)) {
            setState([...state, ...value])
        } else {
            setState([...state, value] as T[])
        }
    } 
}