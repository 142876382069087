
export type PanelsState = Record<PanelId, boolean>;
export type PanelId = keyof typeof panelNames;

export const panelNames = {
    SELECT_PROJECT: 'Select Project',
    UPLOAD_SURVEY: 'Survey File Upload',
    BUILD: 'Build Crosswalk',
    DOWNLOAD: 'Download Stacked Data'
}
