const styles = {
  crosswalkContainer: {
    // overflowX: 'scroll',
    // display: 'flex',
    // width: '100%',
    // margin: '5px',
    // padding: '50px'
  },
  cardBorder: {
    width: '40vh',
    margin: '10px'
  },
  row: {
    display: 'flex'
  },
  iconBox: {
    alignItems: 'center',
    display: 'flex'
  },
  card: {
    border: '1px solid gray',
    padding: '0.5rem 1rem',
    marginBottom: '.5rem',
    cursor: 'move',
    overflowY: 'scroll',
    borderRadius: '6px'
  },
  cardTextStyle: {
    width: '80%',
    float: 'left'
  },
  mappingRadio: {
    margin: '5px'
  },
  buttonContainer: {
    justifyContent: 'center',
    display: 'flex',
    width: '100%'
  }
};

export default styles;