import { Button, MenuItem, Select, Typography } from '@material-ui/core';
import React, {  } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { stackData as stackDataAction, toggleAllResponseIsOpen, setMasterSurvey } from './crosswalk-actions';
import { CrosswalkContainer } from './crosswalk-model';
import styles from './crosswalk.styles';
import { getCrosswalkModes, isCrosswalkSorted, getMasterSurvey } from '../common/redux/redux.selectors';
import { openDataDownload } from '../common/expansion-panel/expansion-panel.actions';

export function BuildCrosswalk() {

    const sorted = useSelector(isCrosswalkSorted);
    const masterSurvey = useSelector(getMasterSurvey);
    const surveyNames = useSelector(getCrosswalkModes);

    const dispatch = useDispatch();

    if (!sorted) {
        return (
            <div>
                You first need to parse the data files.
            </div>
        )
    }

    return (
        <div>
            <div>
                <div>
                    Map responses to
                    <Select style={styles.mappingRadio} value={masterSurvey} onChange={e => changeMasterSurvey(e.target.value as string)}>
                        {generateRadioButtons()}
                    </Select>
                </div>
                <div>
                    <Button onClick={() => toggleAllResponseVisibility(false)}> Hide All </Button>
                    <Button onClick={() => toggleAllResponseVisibility(true)}> Show All </Button>
                </div>
                <CrosswalkContainer />
            </div>
            <div style={styles.buttonContainer} className="stack-data-button-holder">
                <Button variant="contained" color="default" onClick={stackData}>
                    <Typography variant="body1">Stack Data</Typography>
                </Button>
            </div>
        </div>
    )

    function changeMasterSurvey(surveyName: string) {
        dispatch(setMasterSurvey(surveyName));
    }

    function stackData() {
        dispatch(stackDataAction());
        dispatch(openDataDownload())
    }

    function toggleAllResponseVisibility(isOpen: boolean) {
        dispatch(toggleAllResponseIsOpen(isOpen));
    }
    
    function generateRadioButtons() {
        return surveyNames.map(surveyName => {
            return (
                <MenuItem
                    key={surveyName}
                    value={surveyName}
                >
                    {surveyName}
                </MenuItem>
            )
        });
    }

}