import { Button, Card, CardActions, CardContent, TextField, Typography } from "@material-ui/core";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { createNewProject } from "../app/app.actions";
import { getCreateProjectErrorMessage } from "../app/app.selectors";

function CreateProject() {
    const [projectName, setProjectName] = useState<string>('');
    const dispatch = useDispatch();
    const errorMessage = useSelector(getCreateProjectErrorMessage);

    return (
        <Card>
            <CardContent>
                <Typography variant="h6">Create New</Typography>
                <TextField id="standard" label="Project Name" error={!!errorMessage} helperText={errorMessage} onChange={e => setProjectName(e.target.value)} />
            </CardContent>
            <CardActions>
                <Button disabled={!projectName} color="secondary" size="small" onClick={saveNewProject}>Save New Project</Button>
            </CardActions>
        </Card>
    )

    function saveNewProject() {
        dispatch(createNewProject(projectName));
    }
}

export default CreateProject