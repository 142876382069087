import { Button, Card, CardActions, CardContent, TextField, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import styles from './survey-upload.styles';
import { parseNewSurveyFile, readResults, readDataMap } from '../crosswalk/crosswalk.file-parsing';
import { Question, DataMap } from '../crosswalk/crosswalk.types';
import { illegalModeNames } from '../crosswalk/crosswalk-helper';
import { useAppendableState } from '../common/react.helpers';
import { useSelector } from 'react-redux';
import { getExistingSurveys } from './file-upload.selectors';

export type SavedFiles = {
    index: number;
    mode: string;
    results: string;
    dataMap: DataMap[] | null;
    survey: Question[];
    surveyFile: File;
}

type SurveyUploadBoxProps = {
    index: number;
    existingMode: string | null;
    otherSources: string[];
    onSave: (save: SavedFiles) => void;
    setSourceName: (sourceName: string) => void;
}

function SurveyUploadBox({ index, existingMode, otherSources, onSave, setSourceName }: SurveyUploadBoxProps) {

    const [isSaved, setIsSaved] = useState(false);
    const [errors, setErrors, appendErrors] = useAppendableState([] as string[]);
    const [mode, setMode] = useState('');
    const [survey, setSurvey] = useState<Question[]>([]);
    const [uploadedSurveyFile, setUploadedSurveyFile] = useState<File>();
    const [results, setResults] = useState<string | null>(null);
    const [dataMap, setDataMap] = useState<DataMap[] | null>(null);
    const [fileNames, setFileNames] = useState<string[]>([]);

    const existingSurveys = useSelector(getExistingSurveys);

    const isExisting = !!existingMode;
    const label = `Stack Source${isExisting ? " (Existing)" : ""}`

    useEffect(() => {
        if (existingMode) {
            setMode(existingMode);
        }
    }, [existingMode])

    useEffect(() => {
        if (existingSurveys && existingMode && existingSurveys[existingMode]) {
            setSurvey(existingSurveys[existingMode]);
        }
    }, [existingSurveys, existingMode])

    return (
        <form style={styles.form}>
            <Card style={{ backgroundColor: isSaved ? '#dddddd' : 'white' }}>
                <CardContent>
                    <Typography variant="subtitle2" color="secondary">{index === 0 ? "Master" : "\u00A0"}</Typography>
                    <TextField
                        label={label}
                        style={{ width: "100%", marginTop: "12px" }}
                        value={mode}
                        InputProps={{ disabled: isExisting }}
                        onChange={e => handleModeChange(e.target.value)}></TextField>

                    {isExisting ?
                        (
                            <div className="file-upload">
                                Data File (.txt or .asc) (Existing survey file being used from persistence)
                                <input type='file' accept=".txt,.asc" onChange={e => handleDataFileUpdload(e.target.files)} />
                            </div>
                        ) : (
                            <div className="file-upload">
                                Survey File (.gsg or .xml) and Data File (.txt or .asc)
                                <input type='file' accept=".xml,.gsg,.txt,.asc" onChange={e => handleSurveyUpload(e.target.files)} multiple />
                            </div>
                        )
                    }
                    {
                        !!fileNames.length ? <div className="file-name-container">{fileNames.map(f => <p key={f}>{f}</p>)}</div> : null
                    }

                    <div className="file-upload">
                        Map File (.txt) <Typography variant="caption" color="textSecondary" display="inline" >(Optional)</Typography>
                        <input type='file' accept='.txt' onChange={e => handleDataMapUpload(e.target.files)} />
                    </div>

                    {errors.map((e, i) => <p key={i} className="file-upload-error">{e}</p>)}

                </CardContent>

                <CardActions>
                    <Button onClick={saveSurveyFiles}>Save</Button>
                </CardActions>
            </Card>
        </form>
    )


    function handleModeChange(mode: string) {
        setErrors([]);
        setIsSaved(false);
        setMode(mode);
        setSourceName(mode);

        if (isExisting) {
            appendErrors(['Cannot change the Stack Source of an existing mapping.'])
        }

        if (otherSources.includes(mode)) {
            appendErrors(['Please select a unique stack source name.'])
        }

        if (illegalModeNames.includes(mode)) {
            appendErrors(['That name is not allowed.'])
        }
    }


    function hasError(possible: string[] | UploadedFiles): possible is string[] {
        return Array.isArray(possible) && possible.length > 0;
    }

    async function handleSurveyUpload(possibleFiles: FileList | null) {

        if (possibleFiles === null) {
            return setErrors(['Please select files']);
        }

        const files = Array.from(possibleFiles);
        const result = checkForSurveyErrors(files);
        if (hasError(result)) {
            setFileNames([]);
            return setErrors(result);
        }

        setIsSaved(false);
        setUploadedSurveyFile(result.surveyFile);
        setFileNames(files.map(f => f.name));
        setErrors([]);

        try {
            setSurvey(await parseNewSurveyFile(mode, result.surveyFile));
            setResults(await readResults(result.resultsFile))
        } catch (e) {
            if (e instanceof Error) {
                appendErrors(e.message.split('\n'));
            }
        }
    }

    async function handleDataFileUpdload(possibleFiles: FileList | null) {
        if (possibleFiles === null) {
            return setErrors(['Please select files']);
        }

        const files: File[] = Array.from(possibleFiles);

        setIsSaved(false);
        setFileNames(files.map(f => f.name));

        try {
            setResults(await readResults(files[0]))
            setErrors([]);
        } catch (e) {
            if (e instanceof Error) {
                setErrors(e.message.split('\n'))
            }
        }
    }

    async function handleDataMapUpload(possibleFiles: FileList | null) {
        if (possibleFiles === null) {
            return; // No error if no optional file
        }

        const files = Array.from(possibleFiles);
        if (files.length === 0) {
            return; // No error if no optional file
        }

        setIsSaved(false);

        try {
            setDataMap(await readDataMap(files[0]))
            setErrors([]);
        } catch (e) {
            if (e instanceof Error) {
                setErrors(e.message.split('\n'))
            }
        }
    }

    type UploadedFiles = { surveyFile: File; resultsFile: File };
    function checkForSurveyErrors(files: File[]): string[] | UploadedFiles {
        const errors: string[] = [];
        const lowercaseFileNames = files.map(f => f.name.toLowerCase());

        const allCorrectTypes = lowercaseFileNames.every(f => f.endsWith('.gsg') || f.endsWith('.xml')
            || f.endsWith('.txt') || f.endsWith('.asc'));
        const surveyFileIndex = lowercaseFileNames.findIndex(f => f.endsWith('.gsg') || f.endsWith('.xml'));
        const dataFileIndex = lowercaseFileNames.findIndex(f => f.endsWith('.txt') || f.endsWith('.asc'));

        if (!allCorrectTypes) {
            errors.push('All files must be of type .gsg, .xml, .asc, or .txt');
        }

        if (!isExisting && surveyFileIndex === -1) {
            errors.push('Please select a survey file of type .gsg or .xml');
        }

        if (dataFileIndex === -1) {
            errors.push('Please select a data file of type .asc or .txt');
        }

        if (errors.length) {
            return errors;
        }

        return { resultsFile: files[dataFileIndex], surveyFile: files[surveyFileIndex] };
    }

    function saveSurveyFiles() {
        let errors: string[] = [];

        if (!mode) {
            errors.push('Please select a unique stack source.');
        }

        if (!isExisting && !survey.length) {
            errors.push('Please select a Survey File.');
        }

        if (!results) {
            errors.push('Please select a Data File.');
        }

        if (errors.length) {
            return setErrors(errors);
        }

        onSave({
            index: index,
            mode: mode as string,
            dataMap: dataMap,
            results: results as string,
            survey: survey,
            surveyFile: uploadedSurveyFile as File
        });
        setIsSaved(true);
    }
}

export default SurveyUploadBox
